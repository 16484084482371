import React, { useCallback, useEffect, useState } from 'react';
import { useGlobalState } from '../../store/store';
import { fetchStatisticsData } from '../../store/thunks';
import { ICategory, IDateRangeState } from '../../utils/DataTypes';
import DateStrategyPicker from '../elements/DateStrategyPicker';
import ExpenseRevenuePicker from '../elements/ExpenseRevenuePicker';
import { isValidDate } from '../elements/utils';
import { FilterBar } from './categoriesList/FilterBar';
import { DragNDropTable } from './categoriesList/table/DragNDropTable';

const handleNoData = (dateRange: IDateRangeState): Boolean => {
	if (dateRange.strategy === 'Monthly') {
		return dateRange.monthly.values.length === 0;
	} else if (dateRange.strategy === 'Yearly') {
		return dateRange.yearly.values.length === 0;
	}
	return false;
};

export const CategoriesTablePage = () => {
	//globals
	const [globalTransactionType, setGlobalTransactionType] = useGlobalState('transactionType');
	const [globalDateRange, setGlobalDateRange] = useGlobalState('dateRange');

	//locals
	const [transactionType, setTransactionType] = useState<'EXPENSE' | 'REVENUE'>(globalTransactionType);
	const [dateRange, setDateRange] = useState<IDateRangeState>(globalDateRange);
	const [categories, setCategories] = useState<ICategory[]>([]);
	const [paymentsFilter, setPaymentsFilter] = useState<string>('');
	const [noData, setNoData] = useState<Boolean>(false);

	const handleChangeTransactionType = useCallback(
		(newTransactionType: 'EXPENSE' | 'REVENUE') => {
			setNoData(handleNoData(dateRange));
			setTransactionType(newTransactionType);
			setGlobalTransactionType(newTransactionType);
		},
		[dateRange]
	);

	const handleChangeDateRange = useCallback((newDateRange: IDateRangeState) => {
		setNoData(handleNoData(newDateRange));
		setDateRange(newDateRange);
		setGlobalDateRange(newDateRange);
	}, []);

	const handleChangeCategories = (newCategories: ICategory[]) => {
		setCategories(newCategories);
	};

	//* UseEffect that should fetch API categories on parameters change
	useEffect(() => {
		if (!isValidDate(dateRange.active.dateFrom) || !isValidDate(dateRange.active.dateTo)) {
			return;
		}
		const abortController = new AbortController();

		fetchStatisticsData(dateRange.active, transactionType, handleChangeCategories, abortController.signal);
		return () => {
			//? for some reason canceleld request is the last one and no data is shown
			//? abortController.abort();
		};
	}, [dateRange.active, transactionType]);

	// TODO should be handled by the useEffect above
	const reloadTable = () => {
		if (!isValidDate(dateRange.active.dateFrom) || !isValidDate(dateRange.active.dateTo)) {
			return;
		}
		fetchStatisticsData(dateRange.active, transactionType, handleChangeCategories);
	};

	return (
		<>
			<ExpenseRevenuePicker
				transactionType={transactionType}
				changeTransactionType={handleChangeTransactionType}
			/>
			<DateStrategyPicker key={2} dateRange={dateRange} setDateRange={handleChangeDateRange} />
			<FilterBar setFilter={setPaymentsFilter} />
			<DragNDropTable
				key={transactionType}
				noData={noData}
				transactionType={transactionType}
				categories={categories}
				reloadTable={reloadTable}
				paymentsFilter={paymentsFilter}
			/>
		</>
	);
};
