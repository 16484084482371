import React from 'react';
import { useNavigate } from 'react-router';
import { LOGIN_PATH } from '../../../utils/pagePaths';
import { useHandleRegister } from '../../auth/AuthSystemUtils';
import useGlobalSnackbar from '../../elements/_Snackbar/useGlobalSnackbar';
import { LoginWrapper } from '../../elements/_LoginComponents/LoginWrapper';
import { RegisterForm } from '../../elements/_LoginComponents/RegisterForm';

export const RegisterPage = () => {
	const { Snackbar, onSnackbarOpen } = useGlobalSnackbar();
	const pathFrom = new URLSearchParams(window.location.search).get('path');
	const redirecteQueryParam = pathFrom ? `?path=${pathFrom}` : '';
	const navigate = useNavigate();

	const handleLogin = (email: string, password: string) => {
		useHandleRegister({ email: email, password: password }).then((error: any) => {
			if (error == null) {
				onSnackbarOpen({
					type: 'success',
					message: 'Account has been created!',
				});

				setTimeout(() => {
					navigate(LOGIN_PATH + redirecteQueryParam);
				}, 1500);
			} else if (error === 400) {
				onSnackbarOpen({
					type: 'failure',
					message: "Incorrect request. Make sure You've putted correct data and try again.",
				});
			} else if (error === 409) {
				onSnackbarOpen({
					type: 'failure',
					message: 'Login with such email already exists!',
				});
			} else {
				onSnackbarOpen({
					type: 'failure',
					message: 'Unexpected error, try again later.',
				});
			}
		});
	};

	return (
		<LoginWrapper>
			<RegisterForm handleRegister={handleLogin} redirecteQueryParam={redirecteQueryParam} />
			<Snackbar />
		</LoginWrapper>
	);
};
