import { calculatePercents } from '../../../utils/calculation';
import {
    ICategory,
    IPieChartCategory,
    IPieChartFill,
} from '../../../utils/DataTypes';
import { isValidObject } from '../categoriesList/table/dndTableUtils';

export const PIE_CHART_LEGENDS: any[] = [
    {
        anchor: 'bottom-left',
        direction: 'column',
        justify: false,
        translateX: 50,
        translateY: 60,
        itemsSpacing: 10,
        itemWidth: 100,
        itemHeight: 18,
        itemTextColor: '#999',
        itemDirection: 'left-to-right',
        itemOpacity: 1,
        symbolSize: 21,
        symbolShape: 'circle',
        effects: [
            {
                on: 'hover',
                style: {
                    itemTextColor: '#000',
                },
            },
        ],
    },
];

export const STYLES: any[] = [
    {
        id: 'dots',
        type: 'patternDots',
        background: 'inherit',
        color: 'rgba(255, 255, 255, 0.3)',
        size: 4,
        padding: 1,
        stagger: true,
    },
    {
        id: 'lines',
        type: 'patternLines',
        background: 'inherit',
        color: 'rgba(255, 255, 255, 0.3)',
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
    },
    {
        id: 'squares',
        type: 'patternSquares',
        size: 4,
        padding: 4,
        stagger: true,
        background: 'inherit',
        color: 'rgba(255, 255, 255, 0.3)',
    },
];

export const curencyFormatter = new Intl.NumberFormat('en-GB', {
    style: 'decimal',
    minimumFractionDigits: 2,
  });


export const calculateTotalAmountForCategory = (
    category: ICategory
): number => {
    let totalAmount: number = 0;
    category.paymentRecords.map(paymentRecord => {
        totalAmount += Math.abs(paymentRecord.amount);
    });
    return totalAmount;
};

export const generateLegend = (fetchedData: ICategory[]): IPieChartFill[] => {
    if (!Array.isArray(fetchedData) || fetchedData.length === 0) return [];
    const colouringTypes = ['dots', 'lines', 'squares', ''];
    return fetchedData
        .filter(e => isValidObject(e))
        .map((category): IPieChartFill => {
            const random = Math.floor(Math.random() * colouringTypes.length);
            return {
                id: `${colouringTypes[random]}`,
                match: {
                    id: `${category.categoryName ? category.categoryName : 'Unassigned'}`,
                },
            };
        });
};

export const mapCategoryListToChartData = (
    sourceCategoryList: ICategory[]
): IPieChartCategory[] => {
    if (!Array.isArray(sourceCategoryList) || sourceCategoryList.length === 0)
        return [];

    let categoriesForChart = sourceCategoryList.filter(
        element =>
            isValidObject(element) &&
            Array.isArray(element.paymentRecords) &&
            element.paymentRecords.length > 0
    );

    let totalSum = 0;
    categoriesForChart.map((category): void => {
        totalSum += calculateTotalAmountForCategory(category);
    });

    let pieChartCategories = categoriesForChart.map(
        (category): IPieChartCategory => {
            const totalAmount: number =calculateTotalAmountForCategory(category);
            const categoryName = category.categoryName ? category.categoryName : 'Unassigned'
            return {
                id: `${categoryName}`,
                label: `${categoryName} ${calculatePercents(
                    totalAmount,
                    totalSum
                )}`,
                value: `${totalAmount.toFixed(2)}`,
                color: `hsl(${Math.random() * 100}, 70%, 50%)`,
            };
        }
    );

    pieChartCategories = pieChartCategories.sort((e1, e2): number => {
        const e1Int = Number.parseFloat(e1.value);
        const e2Int = Number.parseFloat(e2.value);
        if (e1Int > e2Int) return -1;
        else if (e1Int < e2Int) return 1;
        else return 0;
    });

    return pieChartCategories;
};
