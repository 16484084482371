import React, { FC, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { BASE_PATH, LOGIN_PATH } from '../../utils/pagePaths';
import { AUTH_TOKEN_PARAM, getSessionParam } from './AuthSystemUtils';

interface IProps {
	children: ReactNode;
}

export const AuthRouter: FC<IProps> = ({ children }) => {
	const auth = getSessionParam(AUTH_TOKEN_PARAM);
	const location = useLocation();

	const buildPath = (destPath: string) => {
		return destPath === BASE_PATH ? LOGIN_PATH : LOGIN_PATH + '?path=' + destPath;
	};

	return <>{auth ? children : <Navigate to={buildPath(location.pathname)} />}</>;
};
