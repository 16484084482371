import React, { FC, useEffect, useState } from 'react';
// @ts-ignore
import styled from '@emotion/styled';
// @ts-ignore
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { IDateRangeState } from '../../../utils/DataTypes';
import { elementColor } from '../../../utils/constants';

export const DatePickerWrapper = styled.div`
    text-align: center;
    margin: 50px 0;
    padding: 20px 0;

    .react-daterange-picker__calendar {
        z-index: 1051;
    }

    .react-calendar__tile--active {
        background: ${elementColor};
        &:hover {
            background: #65afa3;
        }
    }
    .react-calendar__tile--hasActive {
        background: #acebe0;
        &:hover {
            background: #c6f0e9;
        }
    }
    .react-calendar__tile--now {
        background: #27d1b5;
        &:hover {
            background: #6db6aa;
        }
    }
`;

interface IProps {
    dateRange: IDateRangeState;
    setDateRange: (newDateRange: IDateRangeState) => void;
}

export const DatePicker: FC<IProps> = ({ dateRange, setDateRange }) => {
    const [dates, setDates] = useState<Date[]>();

    useEffect(() => {
        if (dateRange.custom) {
            setDates([
                new Date(dateRange.custom.dateFrom),
                new Date(dateRange.custom.dateTo),
            ]);
        }
    }, []);

    useEffect(() => {
        if (dates?.length === 2) {
            const newDateRange = {
                dateFrom: dateToRequestData(dates[0]),
                dateTo: dateToRequestData(dates[1]),
            };

            setDateRange({
                ...dateRange,
                active: {
                    dateFrom: newDateRange.dateFrom,
                    dateTo: newDateRange.dateTo,
                },
                custom: {
                    ...dateRange.custom,
                    dateFrom: newDateRange.dateFrom,
                    dateTo: newDateRange.dateTo,
                },
            });
        }
    }, [dates]);

    const dateToRequestData = (date: Date): string => {
        const month = date.getMonth() + 1;
        let stringMonth = `${month}`;
        if (month < 10) stringMonth = `0${month}`;

        const dayOfMonth = date.getDate();
        let stringDayOfMonth = `${date.getDate()}`;
        if (dayOfMonth < 10) stringDayOfMonth = `0${date.getDate()}`;

        return `${date.getFullYear()}-${stringMonth}-${stringDayOfMonth}`;
    };
    return (
        <DatePickerWrapper>
            <DateRangePicker onChange={setDates} value={dates} />
        </DatePickerWrapper>
    );
};
