import React, { useCallback, useState } from 'react';
import { IPaymentTile } from '../../../../utils/DataTypes';
import { ModifyPaymentModal } from './ModifyPaymentModal';

export default function usePaymentModal(reloadTable: () => void) {
	const [payment, setPayment] = useState<IPaymentTile>({} as IPaymentTile);
	const [categoryId, setCategoryId] = useState<number>();
	const [open, setOpen] = useState<boolean>(false);

	const onEditOpen = (argPayment: IPaymentTile) => {
		setPayment(argPayment);
		setOpen(true);
        console.log('PaymentModal opened: ', payment);
	};

	const onNewOpen = (transactionType?: string, categoriId?: number) => {
		const newPayment = {} as IPaymentTile;
		newPayment.paymentDate = new Date().toISOString();
		newPayment.transactionType = transactionType! as 'EXPENSE' | 'REVENUE';
		
        setPayment(newPayment);
		setCategoryId(categoriId);
		setOpen(true);
	};

	const onClose = () => {
		setPayment({} as IPaymentTile);
		setOpen(false);
	};

	const PaymentModal = useCallback(
		() => (
			<ModifyPaymentModal
				open={open}
				payment={payment}
				categoryId={categoryId}
				reloadTable={reloadTable}
				handleCloseDialog={onClose}
			/>
		),
		[payment]
	);

	return {
		PaymentModal,
		onEditPaymentModalOpen: onEditOpen,
		onNewPaymentModalOpen: onNewOpen
	};
}
