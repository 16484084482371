import styled from "@emotion/styled";
import React, { FC } from "react";
import { IPaymentTile } from "../../../../utils/DataTypes";
import { TileWrapper } from "./TileWrapper";
import { fontColor, textShadow, textColor } from "../../../../utils/constants";

const InnerTile = styled.div`
    display: flex;
    flex-direction: column;
    color: ${textColor};
    padding: 5px 0;
`;

const Title = styled.div`
    font-weight: bold;
    margin-bottom: 15px;
`;

const Details = styled.div`
    font-size: 0.9rem;
    margin: 1px 0;
    color: #777;
`;

const Summary = styled.div`
    font-size: 0.9rem;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    color: #aaa;
`;

const Ammount = styled.span`
    font-size: 1.1rem;
    font-weight: bold;
    color: ${fontColor};
    text-shadow: ${textShadow};
`
interface IProps {
    paymentRecord: IPaymentTile,
    index: number,
    reloadTable: () => void
}

export const Tile: FC<IProps> = ({
    paymentRecord,
    index,
    reloadTable
}) =>
    <TileWrapper index={index} paymentRecord={paymentRecord} reloadTable={reloadTable}>
        <InnerTile>
            <Title>{paymentRecord.title}</Title>
            {paymentRecord.paymentReceiver ? <Details>Receiver: {paymentRecord.paymentReceiver}</Details> : <></>}
            {paymentRecord.targetAccountNumber ? <Details>Target account: {paymentRecord.targetAccountNumber}</Details> : <></>}
            <Summary>
                <span>{paymentRecord.paymentDate}</span>
                <Ammount>
                    {paymentRecord.amount} zł
                </Ammount>
            </Summary>
        </InnerTile>
    </TileWrapper>
