import React from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';
import './App.scss';
import { AuthRouter } from './components/auth/AuthRouter';
import { PublicRouter } from './components/auth/PublicRouter';
import { Layout } from './components/sidebar/Layout';
import { fallbackRoutes } from './routes/fallback';
import { privateRoutes } from './routes/private';
import { publicRoutes } from './routes/public';
import { IRoute } from './utils/DataTypes';

const App = () => {
    const parseRouteObjects = (
        routes: IRoute[],
        isPrivate: boolean = false
    ): RouteObject[] => {
        return routes.map((route) => ({
            path: route.path,
            element: isPrivate ? (
                <AuthRouter>
                    <Layout>{route.element}</Layout>
                </AuthRouter>
            ) : (
                <PublicRouter>{route.element}</PublicRouter>
            ),
        }));
    };

    const publicRouteObjects = parseRouteObjects(publicRoutes);
    const privateRouteObjects = parseRouteObjects(privateRoutes, true);
    const fallbackRouteObjects = parseRouteObjects(fallbackRoutes);

    const routes = [
        ...publicRouteObjects,
        ...privateRouteObjects,
        ...fallbackRouteObjects,
    ];

    const allRoutes = useRoutes(routes);

    return <React.Fragment> {allRoutes} </React.Fragment>;
};

export default App;
