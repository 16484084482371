import { DraggableLocation, DropResult } from '@hello-pangea/dnd';
import { v4 as uuidv4 } from 'uuid';
import {
    ICategory,
    ICategoryColumn,
    IPaymentRecord,
    IPaymentTile,
} from '../../../../utils/DataTypes';
import { apiUpdateCategoriesOrder } from '../../../../utils/api';

export const mapCategory2Column = (category: ICategory): ICategoryColumn => {
    var payments;
    if (
        category.paymentRecords === undefined ||
        category.paymentRecords === null
    ) {
        payments = [] as IPaymentRecord[];
    } else {
        payments = category.paymentRecords;
    }

    return {
        ...category,
        droppableId: `droppable-${uuidv4()}`,
        paymentRecords: payments.map(payment => {
            return {
                ...payment,
                draggableId: `draggable-${uuidv4()}`,
            };
        }),
    };
};

export const mapCategories2Columns = (
    categoryList: ICategory[]
): ICategoryColumn[] => {
    const categories = categoryList
        .filter(
            category =>
                isValidObject(category) &&
                isValidObject(category.paymentRecords)
        )
        .map(category => mapCategory2Column(category))
        .sort((a, b) => {
            if (a.columnOrder > b.columnOrder) {
                return 1;
            } else if (a.columnOrder < b.columnOrder) {
                return -1;
            }
            return 0;
        });
    return categories;
};

export const reorder = (
    array: Array<any>,
    sourceIndex: number,
    destinationIndex: number
): Array<any> => {
    const arrayCopy = [...array];
    const [removed] = arrayCopy.splice(sourceIndex, 1);
    arrayCopy.splice(destinationIndex, 0, removed);
    return arrayCopy;
};

export const move = (
    sourcePayments: IPaymentTile[],
    destinationPayments: IPaymentTile[],
    droppableSource: DraggableLocation,
    droppableDestination: DraggableLocation
): IMoveResult => {
    const sourceCopy = [...sourcePayments];
    const destCopy = [...destinationPayments];
    const [removed]: IPaymentTile[] = sourceCopy.splice(
        droppableSource.index,
        1
    );
    destCopy.splice(droppableDestination.index, 0, removed);

    return {
        newSource: sourceCopy,
        newDestination: destCopy,
    };
};

interface IMoveResult {
    newSource: IPaymentTile[];
    newDestination: IPaymentTile[];
}

export const handleColumnOnDragEnd = (
    result: DropResult,
    categories: ICategoryColumn[]
): ICategoryColumn[] => {
    const { source, destination } = result;
    if (!destination) return categories;
    
    const sourceCategory = categories[source.index];
    const reordereredCategories = reorder(
        categories,
        source.index,
        destination!.index
    );
    reordereredCategories.forEach((category, idx) => category.columnOrder = idx);

    const newOrder = reordereredCategories.reduce((acc, category, idx) => {
        if (category.id === null) return acc;
        acc[`${category.id}`] = idx;
        return acc;
    }, {});

    apiUpdateCategoriesOrder(newOrder, sourceCategory.transactionType);

    return reordereredCategories;
};

/**
 * Method that is being used to determine the index of the unassigned category
 * It sums the indexes of all categories and subtracts it from the expected sum of indexes
 * <br>
 * <p>Example:</p>
 * <code>
 * [1,2,3,5]
 * length = 4 + 1 as we are adding the unassigned category
 * 1+2+3+5 = 11 - actual index sum
 * 1+2+3+4+5 = 15 - expected index sum
 * 15 - 11 = 4 - index of the unassigned category
 * thus: 
 * [1,2,3,[X=4],5]
 * </code>
 * @param categories 
 * @returns index of the unassigned category
 */
export const determineIndexForUnassignedCategory = (
    categories: ICategory[]
) => {
    var expectedIndexSum = categories.length;
    const actualIndexSum = categories.reduce((acc, category, idx) => {
        expectedIndexSum += idx;
        return acc + category.columnOrder;
    }, 0);

    return expectedIndexSum - actualIndexSum;
};

export const handleTileReorderOnDragEnd = (
    result: DropResult,
    categories: ICategoryColumn[]
): ICategoryColumn[] => {
    const { source, destination } = result;
    const sourceCategory = findCategory(source.droppableId, categories);
    const sourceCategoryCopy: ICategoryColumn = {
        ...sourceCategory,
        paymentRecords: reorder(
            sourceCategory.paymentRecords,
            source.index,
            destination!.index
        ),
    };
    const categoriesCopy = [...categories];
    const sourceCategoryIndex = categoriesCopy.indexOf(sourceCategory);
    categoriesCopy.splice(sourceCategoryIndex, 1);
    categoriesCopy.splice(sourceCategoryIndex, 0, sourceCategoryCopy);
    return categoriesCopy;
};

export const handleTileMoveOnDragEnd = (
    result: DropResult,
    categories: ICategoryColumn[],
    smartRulesEnabled: boolean,
    onSmartRuleDialogOpen: (movedPayment: IPaymentRecord, destCategoryId: number) => void,
    handleApiMoveCard: (movedPaymentId: number, destCategoryId: number) => void
): ICategoryColumn[] => {
    const { source, destination } = result;
    const sourceCategory = findCategory(source.droppableId, categories);
    const destinationCategory = findCategory(
        destination!.droppableId,
        categories
    );
    const moveResult = move(
        sourceCategory.paymentRecords,
        destinationCategory.paymentRecords,
        source,
        destination!
    );
    const categoriesCopy = [...categories];
    const sourceCategoryCopy: ICategoryColumn = {
        ...sourceCategory,
        paymentRecords: moveResult.newSource,
    };
    const destCategoryCopy: ICategoryColumn = {
        ...destinationCategory,
        paymentRecords: moveResult.newDestination,
    };
    const sourceCategoryIndex = categoriesCopy.indexOf(sourceCategory);
    const destCategoryIndex = categoriesCopy.indexOf(destinationCategory);
    categoriesCopy.splice(sourceCategoryIndex, 1, sourceCategoryCopy);
    categoriesCopy.splice(destCategoryIndex, 1, destCategoryCopy);

    const movedPayment = destCategoryCopy.paymentRecords[destination!.index];
    
    /*
    * If the payment is moved to the unassigned category, the destination category id is null
    * and the source category id is null, the payment is being moved from the unassigned category
    * Show modal only if smart rules are enabled and
    *  1. the payment is being moved from an unassigned category (sourceCategory.id === null)
    *  2. the payment is being moved to a non unassigned category (destCategoryCopy.id !== null)
    */
    if (smartRulesEnabled && sourceCategory.id === null && destCategoryCopy.id !== null) {
        onSmartRuleDialogOpen(movedPayment, destCategoryCopy.id);
    }
    handleApiMoveCard(movedPayment.id, destCategoryCopy.id);
    return categoriesCopy;
};

const findCategory = (
    targetId: string,
    categories: ICategoryColumn[]
): ICategoryColumn => {
    return categories.filter(value => value.droppableId === targetId)[0];
};

export const initCategoriesKey = (
    transactinType: 'EXPENSE' | 'REVENUE'
): 'revenueCategories' | 'expenseCategories' => {
    if (transactinType === 'EXPENSE') {
        return 'expenseCategories';
    } else {
        return 'revenueCategories';
    }
};

export const isValidObject = (obj: any): boolean => {
    return obj !== null && obj !== undefined;
};
