import styled from '@emotion/styled';
import { InputAdornment, TextField } from '@mui/material';
import React, { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { activeInputColor, errorInputColor } from '../../../utils/constants';
import { Noop, RefCallBack } from 'react-hook-form';

export const StyledTextField = styled(TextField)`
	width: 100%;
	margin: 10px 0;

	color: #343535c1;
	border-color: #343535c1;
	caret-color: black;

	.MuiInputLabel-root.Mui-focused,
	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border-color: ${activeInputColor};
		color: ${activeInputColor};
	}

	.MuiInputLabel-root.Mui-focused.Mui-error,
	.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
		color: ${errorInputColor};
		border-color: ${errorInputColor};
	}
`;

interface IProps {
	label: string;
	value: any;
	onChange?: (newValue: any) => void;
	error?: boolean;
	type?: string;
	onBlur?: Noop;
	disabled?: boolean;
	placeholder?: string;
	startAdornment?: React.JSX.Element;
	endAdornment?: React.JSX.Element;
	inputRef?: RefCallBack;
}

export const BasicTextField: FC<IProps> = ({ label, value, type, onBlur, onChange, error, placeholder, startAdornment, endAdornment, disabled, inputRef }) => {
	return (
		<StyledTextField
			id={uuidv4().toString()}
			size="small"
			variant="outlined"
			inputRef={inputRef}
			type={type ? type : 'text'}
			label={label}
			onBlur={onBlur}
			value={value}
			disabled={disabled}
			placeholder={placeholder}
			InputProps={{
				startAdornment: startAdornment && <InputAdornment position="start">{startAdornment}</InputAdornment>,
				endAdornment: endAdornment && <InputAdornment position="end">{endAdornment}</InputAdornment>
			}}
			onChange={(event: any) => (onChange ? onChange(event.target.value) : () => {})}
			error={error}
		/>
	);
};
