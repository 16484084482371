import { ILineChartEntry, ILineChartEntryData, IStatementSummary } from "../../../utils/DataTypes";
import { IMark } from "../AnalysePage";

export const AXIS_BOTTOM: any = {
    orient: 'bottom',
    tickSize: 10,
    tickPadding: 10,
    tickRotation: -90,
    truncateTickAt: 40,
    legend: 'Time',
    legendOffset: -36,
    legendPosition: 'middle',
};

export const AXIS_LEFT: any = {
    orient: 'left',
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: 'Amount',
    legendOffset: -60,
    legendPosition: 'middle',
};

export const ANALYSE_LEGENDS: any[] = [
    {
        anchor: 'top',
        direction: 'row',
        justify: false,
        translateX: 10,
        translateY: -20,
        itemsSpacing: 5,
        itemDirection: 'left-to-right',
        itemWidth: 90,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 20,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
            {
                on: 'hover',
                style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1,
                },
            },
        ],
    },
];


export const mapStatementSummaryToChartData = (
    statement: IStatementSummary,
    monthLimit: number[]
): ILineChartEntry => ({
    id: `${statement.transactionType}`,
    color: 'hsl(82, 70%, 50%)',
    data: statement.monthStatements
        .filter(
            (value, index) =>
                index >= monthLimit[0]!! && index <= monthLimit[1]!!
        )
        .map((entry): ILineChartEntryData => {
            return {
                x: entry.date,
                y: Math.abs(entry.transactionSum),
            };
        }),
});

export const mapStatementSummaryToMarks = (
    statement: IStatementSummary | undefined
): IMark[] => {
    if (statement == undefined) {
        return [];
    }
    
    //* The divider is used to determine how many marks should be displayed on the slider
    var divider = 1;
    if (statement.dateRange.length === 10) {
        divider = 2;
    } else if (statement.dateRange.length > 15) {
        divider = 5;
    } else if (statement.dateRange.length > 20) {
        divider = 7;
    }

    if (statement.monthStatements.length > 15) {
        return statement.dateRange.map((element, index): IMark => {
            var label = '';
            if (index === 0 || index === statement.dateRange.length - 1 || index % divider === 0){
                label = element;
            }
            return {
                value: index,
                label: label,
            };
        });
    }
    return statement.dateRange.map((element, index): IMark => {
        return {
            value: index,
            label: element,
        };
    });
};