import styled from '@emotion/styled';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React, { FC } from 'react';
import { activeInputColor } from '../../../utils/constants';

const StyledFormControl = styled(FormControl)`
    .MuiInputLabel-root.Mui-focused,
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: ${activeInputColor};
        color: ${activeInputColor};
    }
`;

interface IProps<T> {
    elements: T[];
    title: string;
    updateValue: (value: string) => void;
    currentValue: string;
    keyExtractor?: (value: T) => string;
    valueExtractor?: (value: T) => string;
}

export const BasicDropdown: FC<IProps<any>> = ({
    elements,
    title,
    updateValue,
    currentValue,
    keyExtractor,
    valueExtractor,
}) => {
    const extractKey = (obj: any) => 
        (keyExtractor ? keyExtractor(obj) : (valueExtractor ? valueExtractor(obj) : obj));
    
    const extractValue = (obj: any) =>
        (valueExtractor ? valueExtractor(obj) : (keyExtractor ? keyExtractor(obj) : obj));

    return (
        <StyledFormControl fullWidth>
            <InputLabel id="simple-select-label">{title}</InputLabel>
            <Select
                labelId="simple-select-label"
                id="simple-select"
                value={currentValue}
                label="Bank"
                onChange={event => updateValue(event.target.value as string)}
            >
                {elements.map(item => (
                    <MenuItem key={extractKey(item)} value={extractKey(item)}>
                        {extractValue(item)}
                    </MenuItem>
                ))}
            </Select>
        </StyledFormControl>
    );
};
