import { Tooltip, Typography } from '@mui/material';
import React, { FC, ReactElement } from 'react';

interface ITooltipProps {
	children: ReactElement;
	title: string;
	content?: string;
	placement: 'top' | 'bottom' | 'left' | 'right';
}

export const BasicTooltip: FC<ITooltipProps> = ({ children, title, content, placement }) => {
	return (
		<Tooltip
			placement={placement}
			title={
				<React.Fragment>
					<Typography color="inherit">{title}</Typography>
					{content}
				</React.Fragment>
			}
		>
			{children}
		</Tooltip>
	);
};
