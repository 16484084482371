import styled from '@emotion/styled';
import AddTaskIcon from '@mui/icons-material/AddTask';
import { Backdrop } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import React, { FC, useState } from 'react';
import { FiCloudOff } from 'react-icons/fi';
import { GrCloudComputer } from 'react-icons/gr';
import { elementColor } from '../../../../utils/constants';

const label = { inputProps: { 'aria-label': 'Smart rules' } };

const SpeedDialWrapper = styled.div`
    .MuiSpeedDialAction-staticTooltip .MuiSpeedDialAction-staticTooltipLabel {
        width: max-content;
    }
    .MuiSpeedDial-fab,
    .MuiSpeedDial-fab:hover {
        background-color: ${elementColor};
    }
    .MuiBackdrop-root {
        z-index: 1052;
    }
    .MuiSpeedDial-root,
    .MuiSpeedDial-directionDown {
        z-index: 1053 !important;
    }
`;

interface IProps {
    smartRulesEnabled: boolean;
    smartRulesSwitch: () => void;
    addNewCategoryModal: () => void;
}

export const ContextSpeedDial: FC<IProps> = ({
    smartRulesEnabled,
    smartRulesSwitch,
    addNewCategoryModal,
}) => {
    const [localSmartRulesEnabled, setLocalSmartRulesEnabled] =
        useState<boolean>(smartRulesEnabled);
    const [open, setOpen] = useState<boolean>(false);
    
    const handleOpen = (event: React.SyntheticEvent<{}, Event>) => {
        if (event.type === 'focus') {
            return;
        }
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        if (smartRulesEnabled !== localSmartRulesEnabled) {
            smartRulesSwitch();
        }
    };

    const actions = [
        {
            icon: (
                <Checkbox
                    {...label}
                    checked={localSmartRulesEnabled}
                    icon={<FiCloudOff />}
                    checkedIcon={<GrCloudComputer />}
                />
            ),
            name: 'Smart rules',
            id: 'SmartRules',
        },
        { icon: <AddTaskIcon />, name: 'Add category', id: 'AddCategory' },
    ];

    const handleClick = (actionId: string) => {
        switch (actionId) {
            case 'SmartRules':
                setLocalSmartRulesEnabled(!localSmartRulesEnabled);
                break;

            case 'AddCategory':
                handleClose();
                addNewCategoryModal();
                break;

            default: {
                handleClose();
            }
        }
    };

    return (
        <SpeedDialWrapper>
            <Backdrop open={open} />
            <SpeedDial
                ariaLabel="Categories settings"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
                icon={<SpeedDialIcon />}
                onClose={handleClose}
                onOpen={(event) => handleOpen(event)}
                open={open}
            >
                {actions.map(action => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        tooltipOpen
                        onClick={() => handleClick(action.id)}
                    />
                ))}
            </SpeedDial>
        </SpeedDialWrapper>
    );
};
