import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from '@mui/material';

const StyledDialogActions = styled(DialogActions)`
	display: flex;
	justify-content: space-between;
	margin: 0 1rem;
`;

const StyledRightButtons = styled.div`
	margin-left: auto;
	& > *:not(:last-child) {
		margin-right: 1rem;
	}
`;

const StyledDialog = styled(Dialog)`
	.MuiDialog-paper {
		padding: 10px 0;
		min-height: 50vh;
		max-height: 70vh;
		min-width: 40vw;
	}
`;

interface IProps {
	open: boolean;
	onClose: () => void;
	onSubmit?: () => void;
	heading: ReactNode;
	children: ReactNode;
	positiveButton?: ReactNode;
	negativeButton?: ReactNode;
	deleteButton?: ReactNode;
}

export const BasicModal: FC<IProps> = ({
	open,
	onClose,
	onSubmit,
	heading,
	children,
	positiveButton,
	negativeButton,
	deleteButton
}) => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<StyledDialog fullScreen={fullScreen} open={open} onClose={onClose} onSubmit={onSubmit}>
			<DialogTitle id="generic-dialog">{heading}</DialogTitle>
			<DialogContent>{children}</DialogContent>
			<StyledDialogActions>
				{deleteButton && deleteButton}
				<StyledRightButtons>
					{negativeButton}
					{positiveButton}
				</StyledRightButtons>
			</StyledDialogActions>
		</StyledDialog>
	);
};
