import styled from '@emotion/styled';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import DateRangeIcon from '@mui/icons-material/DateRange';
import Stack from '@mui/material/Stack';
import React, { ReactElement, memo, useState } from 'react';
import { IDateRangeState } from '../../utils/DataTypes';
import { StyledSection } from './_BasicComponents/Section';
import { DatePicker } from './_DateComponents/DatePicker';
import { DateSlider } from './_DateComponents/DateSlider';
import { StrategyStepper } from './_DateComponents/StrategyStepper';
import { prepareNewActiveDateRangeBasedOnStrategy } from './utils';

export const StyledStack = styled(Stack)`
	width: 60%;
	margin: 20px auto;
`;

export interface IDateTimeStrategy {
	key: number;
	icon: ReactElement;
	action: 'Yearly' | 'Monthly' | 'Custom';
}

export const STRATEGIES: IDateTimeStrategy[] = [
	{ key: 1, icon: <CalendarViewWeekIcon />, action: 'Yearly' },
	{ key: 2, icon: <CalendarViewMonthIcon />, action: 'Monthly' },
	{ key: 3, icon: <DateRangeIcon />, action: 'Custom' },
];

const getStrategyByAction = (action: string): IDateTimeStrategy => {
	return STRATEGIES.find((value) => value.action === action)!;
};

interface IProps {
	dateRange: IDateRangeState;
	setDateRange: (newRange: IDateRangeState) => void;
}

function DateStrategyPicker({ dateRange, setDateRange: setDateRange }: IProps) {
	const [activeStrategy, setActiveStrategy] = useState<IDateTimeStrategy>(getStrategyByAction(dateRange.strategy));

	const handleStrategyChange = (strategy: IDateTimeStrategy) => {
		setActiveStrategy(strategy);
		setDateRange({
			...dateRange,
			active: prepareNewActiveDateRangeBasedOnStrategy(dateRange, strategy.action),
			strategy: strategy.action,
		});
	};

	return (
		<StyledSection>
			<StyledStack>
				<StrategyStepper activeStrategy={activeStrategy} handleStrategyChange={handleStrategyChange} />
				{activeStrategy.action === 'Custom' ? (
					<DatePicker dateRange={dateRange} setDateRange={setDateRange} />
				) : (
					<DateSlider dateRange={dateRange} setDateRange={setDateRange} strategy={activeStrategy.action} />
				)}
			</StyledStack>
		</StyledSection>
	);
}

export default memo(DateStrategyPicker);