import React, { useCallback, useState } from 'react';
import { GlobalSnackbar } from './GlobalSnackbar';

export interface ISnackbarProps {
    type: 'success' | 'failure';
    message: string;
}

export default function useGlobalSnackbar() {
    const [message, setMessage] = useState('');
    const [type, setType] = useState<'success' | 'failure' | null>(null);

    const onOpen = ({ type, message }: ISnackbarProps) => {
        setMessage(message);
        setType(type);
    };

    const onClose = () => {
        setMessage('');
        setType(null);
    };

    const Snackbar = useCallback(
        () => (
            <GlobalSnackbar message={message} type={type} onClose={onClose} />
        ),
        [message]
    );

    return {
        Snackbar,
        onSnackbarOpen: onOpen,
    };
}
