import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React, { FC, useEffect, useState } from 'react';
import { BasicButtonGroup } from '../../../../elements/_BasicComponents/BasicButtonGroup';
import { BasicTextField } from '../../../../elements/_BasicComponents/BasicTextField';
import { BasicTooltip } from '../../../../elements/_BasicComponents/BasicTooltip';
import { getSmartRuleLabel } from './SmartRuleDialog';

interface IProps {
	row: string;
	isItemSelected: boolean;
	index: number;
	handleCancelNewRule: () => void;
	handleClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, row: string) => void;
	handleUpdateRow: (index: number, newValue: string) => void;
	isSmartRuleUnique: (smartRule: string, categoryId?: number) => boolean;
	categoryId?: number;
}

export const RulesTableRow: FC<IProps> = ({
	row,
	isItemSelected,
	index,
	handleCancelNewRule,
	handleClick,
	handleUpdateRow,
	isSmartRuleUnique,
	categoryId,
}) => {
	const [newValue, setNewValue] = useState<string>(row);
	const [editing, setEditing] = useState<boolean>(index === -1);
	const [touched, setTouched] = useState<boolean>(false);
	const [label, setLabel] = useState<string>('Smart rule');
	const [error, isError] = useState<boolean>(false);

	useEffect(() => {
		setNewValue(row);
	}, [row]);

	useEffect(() => {
		isError(label !== 'Smart rule');
	}, [label]);

	const labelId = `rule-table-${index}`;

	const handleEdit = () => {
		setEditing(true);
	};

	const handleValueChange = (newValue: string) => {
		setNewValue(newValue);
		setLabel(getSmartRuleLabel(newValue, isSmartRuleUnique(newValue, categoryId)));
		setTouched(true);
	};

	const handleValueChangeAccept = () => {
		setEditing(false);
		handleUpdateRow(index, newValue);
	};

	const handleValueChangeReject = () => {
		setNewValue(row);
		setEditing(false);
		handleCancelNewRule();
	};

	return (
		<TableRow hover role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={row} selected={isItemSelected}>
			{editing ? (
				<>
					<TableCell padding="checkbox">
						<Checkbox
							color="primary"
							checked={isItemSelected}
							inputProps={{ 'aria-labelledby': labelId }}
							onClick={(event) => handleClick(event, row)}
						/>
					</TableCell>
					<TableCell padding="none">
						<BasicTextField
							label={label}
							value={newValue}
							onChange={handleValueChange}
							error={touched ? error : false}
						/>
					</TableCell>
					<TableCell align="right">
						<BasicButtonGroup
							key={`editRuleComponent-${row}`}
							disabled={newValue.length === 0 || error}
							ariaLabel="Manage upating rule"
							acceptOnClick={handleValueChangeAccept}
							rejectOnClick={handleValueChangeReject}
						/>
					</TableCell>
				</>
			) : (
				<>
					<TableCell padding="checkbox">
						<Checkbox
							color="primary"
							checked={isItemSelected}
							inputProps={{ 'aria-labelledby': labelId }}
							onClick={(event) => handleClick(event, row)}
						/>
					</TableCell>
					<TableCell component="th" id={labelId} scope="row" padding="none">
						{row}
					</TableCell>
					<TableCell component="th" id={labelId} scope="row" align={'right'} padding="none">
						<BasicTooltip placement="left" title="Edit">
							<IconButton onClick={handleEdit}>
								<EditTwoToneIcon />
							</IconButton>
						</BasicTooltip>
					</TableCell>
				</>
			)}
		</TableRow>
	);
};
