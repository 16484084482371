import styled from '@emotion/styled';
import React, { FC } from 'react';
import SwipeableViews from 'react-swipeable-views';

const StyledSwipeableViews = styled(SwipeableViews)`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 400px;
`;

interface IProps {
    activeStep: number,
    activeDates: string[]
    handleStepChange: (step: number) => void
}

export const SwipeableDateSlider: FC<IProps> = ({ activeStep, activeDates, handleStepChange }) => {
    return (
        <StyledSwipeableViews
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
        >
            {activeDates && activeDates.map((step, index) => (
                <div key={step}>
                    {Math.abs(activeStep - index) <= 2 && (
                        // <Button color="inherit" onClick={() => { }} disabled={dates.length < 2}>
                        step
                        // </Button> 
                        //TODO: make dropdown with tiles to select
                    )}
                </div>
            ))
            }
        </StyledSwipeableViews >
    );
}