import React, { FC, ReactElement } from "react";
import styled from "@emotion/styled";
import { DragDropContext, Droppable, DropResult, DroppableProvided } from '@hello-pangea/dnd';

const ColumnContainer = styled.div`
    display: flex;
    overflow-y: auto; 
`;

interface IProps {
    onDragEnd: (dropResult: DropResult) => void,
    transactionType: 'EXPENSE' | 'REVENUE',
    children: ReactElement
}

export const DragWrapper: FC<IProps> = ({ onDragEnd, transactionType, children }) => {

    return (
        <DragDropContext 
        key={`drag-drop-master-context-${transactionType}`}
        onDragEnd={onDragEnd}>
            <Droppable
                key={`all-columns-${transactionType}`}
                droppableId={`all-columns-${transactionType}`}
                direction="horizontal"
                type={`${transactionType}-column`}
            >
                {(provided: DroppableProvided) => (
                    <ColumnContainer
                        ref={provided.innerRef}
                        {...provided.droppableProps}>

                        {children}

                        {provided.placeholder}
                    </ColumnContainer>
                )}
            </Droppable>
        </DragDropContext>
    );
}