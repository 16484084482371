import { useState } from "react";

    export const newCategoryHook = () => {
    const [showNewCategorySkeleton, setShowNewCategorySkeleton] = useState<boolean>(false);
    const [showNewCategoryModal, setShowNewCategoryModal] = useState<boolean>(false);

    const openNewCategoryModal = () => {
        setShowNewCategoryModal(true);
        setShowNewCategorySkeleton(true);
    }

    const hideNewCategorySkeleton = () => {
        setShowNewCategorySkeleton(false);
    }

    const hideNewCategoryModal = () => {
        setShowNewCategoryModal(false);
    }

    return {
        showNewCategorySkeleton, setShowNewCategorySkeleton, showNewCategoryModal, openNewCategoryModal, hideNewCategorySkeleton, hideNewCategoryModal
    }

}