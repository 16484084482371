import { DropResult } from '@hello-pangea/dnd';
import isEqual from 'lodash.isequal';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useGlobalState } from '../../../../store/store';
import { addSmartRuleAndReloadTableThunk, editSmartRulesAndReloadTableThunk } from '../../../../store/thunks';
import {
	DomainErrorResponse,
	ICategory,
	ICategoryColumn,
	ICreateCategory,
	IPaymentRecord,
	TransactionType
} from '../../../../utils/DataTypes';
import { apiAddCategory, apiDeleteCategoryById, apiMoveCard } from '../../../../utils/api';
import { NoDataYet } from '../../../elements/NoDataYet';
import useGlobalSnackbar from '../../../elements/_Snackbar/useGlobalSnackbar';
import { AddCategoryDialog } from '../category/AddCategoryDialog';
import { CategorySkeleton } from '../category/CategorySkeleton';
import { Column } from '../category/Column';
import { DragWrapper } from '../category/DragWrapper';
import { SmartRuleDialog } from '../category/rules/SmartRuleDialog';
import { newCategoryHook as useNewCategoryHook } from '../hooks/useNewCategoryHook';
import { ContextSpeedDial } from './ContextSpeedDial';
import {
	handleColumnOnDragEnd,
	handleTileMoveOnDragEnd,
	handleTileReorderOnDragEnd,
	mapCategories2Columns as mapAndSortCategories2Columns,
	mapCategory2Column
} from './dndTableUtils';

interface IProps {
	transactionType: 'EXPENSE' | 'REVENUE';
	categories: ICategory[];
	reloadTable: () => void;
	noData: Boolean;
	paymentsFilter: string;
}

export const DragNDropTable: FC<IProps> = ({ transactionType, categories, reloadTable, noData, paymentsFilter }) => {
	const { Snackbar, onSnackbarOpen } = useGlobalSnackbar();
	const [smartRulesEnabled, switchSmartRules] = useGlobalState('smartRulesEnabled');
	const [localCategories, setLocalCategories] = useState<ICategoryColumn[]>([]);

	const {
		showNewCategorySkeleton,
		setShowNewCategorySkeleton,
		showNewCategoryModal,
		openNewCategoryModal,
		hideNewCategoryModal,
		hideNewCategorySkeleton
	} = useNewCategoryHook();

	const [smartRuleModalProps, setSmartRuleModalProps] = useState({
		open: false,
		paymentTitle: '',
		targetCategoryId: 0
	});

	// UseEffect that should update categories on props change
	useEffect(() => {
		if (!Array.isArray(categories)) {
			return;
		}
		if (categories.length >= 0 && !isEqual(categories, localCategories)) {
			setShowNewCategorySkeleton(false);
			setLocalCategories(mapAndSortCategories2Columns(categories));
		} else if (categories.length === 0) {
			setShowNewCategorySkeleton(true);
		}
	}, [categories]);

	const changeSmartRulesEnabled = () => switchSmartRules(!smartRulesEnabled);

	const handleAddNewCategory = (newCategory: ICreateCategory) => {
		hideNewCategoryModal();
		apiAddCategory(newCategory).then((value) => {
			const apiNewCategry = mapCategory2Column(value);
			const categoriesCopy = [apiNewCategry, ...localCategories];
			setLocalCategories(categoriesCopy);
			hideNewCategorySkeleton();

			reloadTable();
		});
	};

	const handleDeleteCategory = (categoryToDeletion: ICategoryColumn) => {
		apiDeleteCategoryById(categoryToDeletion.id)
			.then((response) => {
				if (response.status === 200) {
					onSnackbarOpen({
						message: 'Successfully deleted category.',
						type: 'success'
					});
				}
			})
			.then(() => {
				reloadTable();
				const categoriesCopy = [...localCategories];
				const indexOfTargetCategory = categoriesCopy.indexOf(categoryToDeletion);
				categoriesCopy.splice(indexOfTargetCategory, 1);
				setLocalCategories(categoriesCopy);
			})
			.catch((error: DomainErrorResponse) => {
				if (error.message === '400') {
					onSnackbarOpen({
						message: 'Cannot delete category with payments.',
						type: 'failure'
					});
					return;
				}
				onSnackbarOpen({
					message: `Oops, something went wrong!: ${error}`,
					type: 'failure'
				});
			});
	};

	const handleApiMoveCard = (paymentId: number, targetCategoryId: number) => {
		apiMoveCard(paymentId, targetCategoryId)
			.then((response) => {
				if (response.status === 200) {
					onSnackbarOpen({
						message: 'Successfully moved payment!',
						type: 'success'
					});
					return;
				}
				onSnackbarOpen({
					message: `Something went wrong: ${response.text}`,
					type: 'failure'
				});
			})
			.catch(() => {
				onSnackbarOpen({
					message: 'Something went wrong - Unsuccessful card modification',
					type: 'failure'
				});
			});
	};

	const onDragEnd = (result: DropResult): void => {
		const { source, destination, type } = result;

		if (!destination) {
			return;
		}

		if (type === `${transactionType}-column`) {
			if (source.index === destination.index) {
				return;
			}
			setLocalCategories(handleColumnOnDragEnd(result, localCategories));
			return;
		}

		if (source.droppableId === destination.droppableId) {
			setLocalCategories(handleTileReorderOnDragEnd(result, localCategories));
			return;
		}

		setLocalCategories(
			handleTileMoveOnDragEnd(
				result,
				localCategories,
				smartRulesEnabled,
				handleSmartRuleModalOpen,
				handleApiMoveCard
			)
		);
	};

	const handleApiAddRule = (targetCategoryId: number, ruleContent: string) => {
		addSmartRuleAndReloadTableThunk(
			targetCategoryId,
			ruleContent,
			TransactionType[transactionType],
			onSnackbarOpen
		);
		reloadTable();
	};

	const handleUpdateSmartRules = (newRules: string[], targetCategoryId?: number) => {
		editSmartRulesAndReloadTableThunk(
			targetCategoryId!!,
			newRules,
			TransactionType[transactionType],
			onSnackbarOpen
		);
	};

	const isCategoryNameUnique = useCallback(
		(categoryName: string, categoryId?: number): boolean => {
			return (
				localCategories.filter(
					(category) => category.categoryName === categoryName && category?.id !== categoryId
				).length === 0
			);
		},
		[localCategories]
	);

	const isSmartRuleUnique = useCallback(
		(smartRule: string, categoryId?: number): boolean => {
			return (
				localCategories.filter(
					(category) =>
						category.keyPhrases.filter(
							(value) => value.toLowerCase() === smartRule.toLowerCase() && category?.id !== categoryId
						).length > 0
				).length === 0
			);
		},
		[localCategories]
	);
	const handleSmartRuleModalOpen = (payment: IPaymentRecord, targetCategoryId: number) => {
		if (targetCategoryId == null) {
			return;
		}
		setSmartRuleModalProps({
			open: true,
			paymentTitle: payment.title,
			targetCategoryId: targetCategoryId
		});
	};

	const handleSmartRuleModalClose = () => {
		setSmartRuleModalProps({
			open: false,
			paymentTitle: '',
			targetCategoryId: 0
		});
	};

	return (
		<>
			{noData ? (
				<NoDataYet />
			) : (
				<>
					<DragWrapper onDragEnd={onDragEnd} transactionType={transactionType}>
						<>
							{showNewCategorySkeleton && <CategorySkeleton />}
							{localCategories.map((category, index) => (
								<Column
									key={category.categoryName}
									category={category}
									index={index}
									handleDeleteCategory={handleDeleteCategory}
									reloadTable={reloadTable}
									isCategoryNameUnique={isCategoryNameUnique}
									isSmartRuleUnique={isSmartRuleUnique}
									handleUpdateSmartRules={handleUpdateSmartRules}
									paymentsFilter={paymentsFilter}
								/>
							))}
						</>
					</DragWrapper>
					<ContextSpeedDial
						key={1}
						smartRulesEnabled={smartRulesEnabled}
						smartRulesSwitch={changeSmartRulesEnabled}
						addNewCategoryModal={openNewCategoryModal}
					/>
					<AddCategoryDialog
						key={uuidv4()}
						open={showNewCategoryModal}
						rejectAddNewCategory={() => {
							hideNewCategorySkeleton(), hideNewCategoryModal();
						}}
						addNewCategory={handleAddNewCategory}
						transactionType={transactionType}
						isCategoryNameUnique={isCategoryNameUnique}
						isSmartRuleUnique={isSmartRuleUnique}
					/>
					<SmartRuleDialog
						key={'smart-rule-dialog-wrapper'}
						handleApiAddRule={handleApiAddRule}
						paymentTitle={smartRuleModalProps.paymentTitle}
						targetCategoryId={smartRuleModalProps.targetCategoryId}
						open={smartRuleModalProps.open}
						handleClose={handleSmartRuleModalClose}
						isSmartRuleUnique={isSmartRuleUnique}
					/>
					<Snackbar />
				</>
			)}
		</>
	);
};
