import { AnalysePage } from '../components/pages/AnalysePage';
import { UploadStatement } from '../components/pages/UploadStatement';
import { CategoriesTablePage } from '../components/pages/CategoriesTablePage';
import { InsightsPage } from '../components/pages/InsightsPage';
import { ANALYSE_PATH, BASE_PATH, INSIGHTS_PATH, MANAGE_DND_PATH, UPLOAD_STATEMENT_PATH } from '../utils/pagePaths';
import { MainDashboard } from '../components/pages/MainDashboard';

export const privateRoutes = [
    {
        path: BASE_PATH,
        element: <MainDashboard/>
    },
    {
        path: UPLOAD_STATEMENT_PATH,
        element: <UploadStatement />
    },
    {
        path: INSIGHTS_PATH,
        element: <InsightsPage />
    },
    {
        path: ANALYSE_PATH,
        element: <AnalysePage />,
    },
    {
        path: MANAGE_DND_PATH,
        element: <CategoriesTablePage />,
    }
];