import { jwtDecode } from 'jwt-decode';
import { IToken } from '../../utils/DataTypes';
import { handleLogin, handleRegister } from '../../utils/api';
import { BASE_PATH } from '../../utils/pagePaths';

interface IProps {
    email: string;
    password: string;
}

export const AUTH_TOKEN_PARAM = 'auth-token';
export const USER_EMAIL_PARAM = 'user-email';
export const REFRESH_TOKEN_PARAM = 'refresh-token';

export const useHandleLogin = async ({
    email,
    password,
}: IProps): Promise<'login' | null | any> => {
    return await handleLogin(email, password)
        .then(response => {
            return response.json();
        })
        .then((token: IToken) => {
            if (token) {
                handleRefreshTokenSuccess(token);
                return null;
            }
            return 'login';
        })
        .catch(error => {
            if (
                error &&
                error.response &&
                (error.response.status === 401 || error.response.status === 403)
            ) {
                return 'login';
            }
            return error;
        });
};

export const useHandleRegister = async ({
    email,
    password,
}: IProps): Promise<400 | 409 | null> => {
    return await handleRegister(email, password)
        .then(() => {
            return null;
        })
        .catch(error => {
            if (
                error &&
                error.response &&
                (error.response.status === 400 || error.response.status === 409)
            ) {
                return error.response.status;
            }
            console.log('Unexpected error from register page: ', error);
            return error;
        });
};

export const useHandleLogout = () => {
    sessionStorage.removeItem(AUTH_TOKEN_PARAM);
    sessionStorage.removeItem(REFRESH_TOKEN_PARAM);
    sessionStorage.removeItem(USER_EMAIL_PARAM);
    window.location.replace(BASE_PATH);
};

export const getUserEmail = (): string | null => {
    return getSessionParam(USER_EMAIL_PARAM);
};

export const getSessionParam = (paramName: string) => {
    return sessionStorage.getItem(paramName);
};

export const handleRefreshTokenSuccess = (newToken: IToken) => {
    sessionStorage.setItem(AUTH_TOKEN_PARAM, newToken.accessToken);
    sessionStorage.setItem(
        USER_EMAIL_PARAM,
        jwtDecode(newToken.accessToken).sub!
    );
    sessionStorage.setItem(REFRESH_TOKEN_PARAM, newToken.refreshToken);
};
