import styled from '@emotion/styled';
import { Draggable, DraggableProvided, DraggableStateSnapshot } from '@hello-pangea/dnd';
import React, { FC, ReactNode } from 'react';
import { IPaymentTile } from '../../../../utils/DataTypes';
import usePaymentModal from './usePaymentModal';

const Wrapper = styled.div<DraggingProps>`
	box-sizing: border-box;
	user-select: none;
	padding: 16px;
	margin: 10px;
	transition: background-color 0.2s ease;
	border-radius: 10px;
	background: ${(props: DraggingProps) => (props.isDragging ? '#FFFAFA' : '#ffffff')};
	box-shadow: 1px 1px 5px #00000040;
`;

export interface DraggingProps {
	isDragging: boolean;
}

interface IProps {
	paymentRecord: IPaymentTile;
	index: number;
	children: ReactNode;
	reloadTable: () => void;
}

export const TileWrapper: FC<IProps> = ({ index, paymentRecord, children, reloadTable }) => {
	const { PaymentModal, onEditPaymentModalOpen }  = usePaymentModal(reloadTable);

	return (
		<>
			<Draggable draggableId={paymentRecord.draggableId} index={index}>
				{(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
					<Wrapper
						onClick={() => onEditPaymentModalOpen(paymentRecord)}
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						isDragging={snapshot.isDragging}
					>
						{children}
					</Wrapper>
				)}
			</Draggable>
			<PaymentModal/>
		</>
	);
};
