import styled from '@emotion/styled';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { ButtonGroup } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import { BasicButton } from './BasicButton';

const StyledButtonGroup = styled(ButtonGroup)`
    height: 40px;
    width: 80px;
`;

interface IProps {
    disabled?: boolean | undefined;
    rejectOnClick: () => void;
    acceptOnClick: () => void;
    onSubmit?: () => void;
    startIcon?: ReactNode;
    ariaLabel?: string;
    size?: 'small' | 'medium' | 'large';
}

export const BasicButtonGroup: FC<IProps> = ({
    disabled,
    rejectOnClick,
    acceptOnClick,
    onSubmit,
    ariaLabel,
    startIcon,
    size
}) => {
    return (
        <StyledButtonGroup
            orientation="horizontal"
            aria-label={ariaLabel}
            size={size ? size : 'small'}
        >
            <BasicButton
                key="approve"
                disabled={disabled}
                onClick={acceptOnClick}
                onSubmit={onSubmit}
            >
                {startIcon ? startIcon : <DoneOutlinedIcon />}
            </BasicButton>
            <BasicButton onClick={rejectOnClick} key="reject">
                <CloseSharpIcon />
            </BasicButton>
        </StyledButtonGroup>
    );
};
