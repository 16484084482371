import styled from "@emotion/styled";
import React, { FC, ReactNode, useState } from 'react';
import { SideBar } from "./SideBar";


const ComponentWrapper = styled.div`
    padding: 24px;
	height: 100vh;
    overflow-y: auto;
	transition: 0.7s;
`

const notColapsed = {
    width: "calc(100vw - 318px)",
    marginLeft: "270px"
}

const colapsed = {
    width: "calc(100vw - 128px)",
    marginLeft: "80px"
};

interface IProps {
    children: ReactNode
}

export const Layout: FC<IProps> = ({ children }) => {
    const [isColapsed, setColapsed] = useState<boolean>(false);

    const handleColapse = () => {
        setColapsed(!isColapsed)
    }

    return (
        <>
            <SideBar
                handleClick={handleColapse}
                isColapsed={isColapsed}
            />
            <ComponentWrapper style={isColapsed ? colapsed : notColapsed}>
                {children}
            </ComponentWrapper>
        </>
    );
}