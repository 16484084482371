import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_PATH } from '../../../utils/pagePaths';
import { useHandleLogin } from '../../auth/AuthSystemUtils';
import { LoginForm } from '../../elements/_LoginComponents/LoginForm';
import { LoginWrapper } from '../../elements/_LoginComponents/LoginWrapper';
import useGlobalSnackbar from '../../elements/_Snackbar/useGlobalSnackbar';

export const LoginPage = () => {
	const { Snackbar, onSnackbarOpen } = useGlobalSnackbar();
	const pathFrom = new URLSearchParams(window.location.search).get('path');
	const redirectPath = pathFrom ? pathFrom : BASE_PATH;
	const navigate = useNavigate();

	const handleSubmitLogin = (email: string, password: string) => {
		useHandleLogin({ email: email, password: password }).then((error: string) => {
			if (error == null) {
				navigate(redirectPath);
			} else if (error === 'login') {
				onSnackbarOpen({
					type: 'failure',
					message: 'Incorrect login or password',
				});
			} else {
				onSnackbarOpen({
					type: 'failure',
					message: 'Unable to login: ' + error,
				});
			}
		});
	};

	return (
		<LoginWrapper>
			<LoginForm handleLogin={handleSubmitLogin} redirecteQueryParam={`?path=${redirectPath}`} />
			<Snackbar />
		</LoginWrapper>
	);
};
