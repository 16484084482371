import React from 'react';
import {GiGearHammer} from 'react-icons/gi'
import styled from '@emotion/styled';
import { textColor } from '../../utils/constants';
import { StyledSection } from './_BasicComponents/Section';

const StyledIcon = styled(GiGearHammer)`
  font-size: 40px;
  -webkit-animation: spin 2s infinite alternate;
  animation: spin 2s infinite alternate;
  
  @-moz-keyframes spin {
    from { -moz-transform: rotate(0deg); }
    to { -moz-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
  }
  @keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
  }
`

const StyledText = styled.span`
    padding: 2%;
    font-weight: bold;
    font-size: 20px;
    color: ${textColor};
`

const CenteredWrapper = styled.div`
  margin-top: 5vh;
  text-align: center;
  display: flex;
  flex-direction: column;
`

const GifWrapper = styled.img`
	text-align: center;
	position:relative;
`

export const NoDataYet = () => {
	return (
    <StyledSection>
      <CenteredWrapper>
        <span>
          <StyledIcon/>
        </span>
        <StyledText>
          No data found...<br/>
          Consider importing csv file or changing date range
        </StyledText>
        <span>
          <GifWrapper src={'https://i.gifer.com/B0eS.gif'}/>
        </span>
      </CenteredWrapper>
    </StyledSection>
    )
}