import styled from '@emotion/styled';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { FC, memo, useState } from 'react';
import { TransactionType } from '../../utils/DataTypes';
import { fontColor } from '../../utils/constants';
import { StyledSection } from './_BasicComponents/Section';

const StyledTabs = styled(Tabs)`
	.Mui-selected {
		color: ${fontColor}!important;
	}

	.MuiTabs-indicator {
		background-color: ${fontColor};
		height: 3px;
	}
`;

interface IProps {
	transactionType: 'EXPENSE' | 'REVENUE';
	changeTransactionType: (newValue: 'EXPENSE' | 'REVENUE') => void;
}

function ExpenseRevenuePicker({ transactionType, changeTransactionType }: IProps) {
	const [activeTab, setActiveTab] = useState<number>(TransactionType[transactionType]);

	console.log('Render - expense/revenue');

	const handleActiveTabChange = (event: React.SyntheticEvent, newValue: number) => {
		var newType: 'EXPENSE' | 'REVENUE';
		if (newValue == 0) {
			newType = 'EXPENSE';
		} else {
			newType = 'REVENUE';
		}
		setActiveTab(newValue);
		changeTransactionType(newType);
	};

	return (
		<StyledSection>
			<StyledTabs value={activeTab} onChange={handleActiveTabChange} centered>
				<Tab icon={<UnarchiveOutlinedIcon />} label="EXPENSE" />
				<Tab icon={<ArchiveOutlinedIcon />} label="REVENUE" />
			</StyledTabs>
		</StyledSection>
	);
};

export default memo(ExpenseRevenuePicker);