import styled from '@emotion/styled';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import React, { FC } from 'react';
import { ICategoryColumn } from '../../../../utils/DataTypes';
import useGlobalSnackbar from '../../../elements/_Snackbar/useGlobalSnackbar';
import usePaymentModal from '../tile/usePaymentModal';

const actions = [
	{ icon: <EditTwoToneIcon />, name: 'Edit', operation: 'edit' },
	{ icon: <DeleteForeverTwoToneIcon />, name: 'Delete', operation: 'delete' },
	{ icon: <LibraryAddIcon />, name: 'Add payment', operation: 'addPayment' }
];

const StyledColumnEditDial = styled(SpeedDial)`
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	justify-content: center;

	.MuiSpeedDial-actions {
		top: 45%;
		position: absolute;
	}

	.css-3mwa8g-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab {
		background-color: transparent;
		box-shadow: none;
		border-radius: 0;
	}

	.css-3mwa8g-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab {
		color: black;
		size: 1.2rem;
	}

	.css-159ulhs-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab {
		background-color: rgb(134, 227, 215);
	}

	&:hover {
		.css-3mwa8g-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab {
			color: #817f7f;
		}

		.MuiSpeedDialIcon-icon {
			transform: rotate(90deg);
			-webkit-transform: rotate(90deg);
			-ms-transform: rotate(90deg);
		}

		.MuiSpeedDial-fab {
			background-color: transparent;
		}
	}
`;

const DialWrapper = styled.div`
	width: 10%;

	&:hover {
		cursor: pointer;
	}
`;

interface IProps {
	category: ICategoryColumn;
	handleDeleteCategory: (category: ICategoryColumn) => void;
	handleOpenRulesDialog: () => void;
	reloadTable: () => void;
}

export const ColumnEditDial: FC<IProps> = ({ category, handleDeleteCategory, handleOpenRulesDialog, reloadTable }) => {
	const { Snackbar, onSnackbarOpen } = useGlobalSnackbar();
	const { PaymentModal, onNewPaymentModalOpen } = usePaymentModal(reloadTable);

	const handleActionClick = (action: any, operation: string) => {
		category.id;
		switch (operation) {
			case 'edit':
				handleOpenRulesDialog();
				break;

			case 'delete':
				{
					if (category.paymentRecords.length !== 0) {
						onSnackbarOpen({
							message: 'Unable to delete category with payment records!',
							type: 'failure'
						});
						return;
					}
					handleDeleteCategory(category);
				}
				break;

			case 'addPayment':
				onNewPaymentModalOpen(category.transactionType, category.id);
				break;

			default:
				return;
		}
	};

	return (
		<DialWrapper>
			<StyledColumnEditDial
				ariaLabel="Column settings dropdown"
				sx={{ position: 'relative' }}
				direction="down"
				icon={<SpeedDialIcon icon={<MoreVertOutlinedIcon />} />}
			>
				{actions.map((action) => (
					<SpeedDialAction
						key={action.name}
						icon={action.icon}
						tooltipTitle={action.name}
						onClick={(event) => handleActionClick(event, action.operation)}
					/>
				))}
			</StyledColumnEditDial>
			<Snackbar />
			<PaymentModal />
		</DialWrapper>
	);
};
