import styled from '@emotion/styled';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Slide, { SlideProps } from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import { TransitionProps } from '@mui/material/transitions';
import React, { FC, ReactNode, useEffect } from 'react';

export const StyledSnackbar = styled(Snackbar)<SnackbarLevelProps>`
    .MuiSnackbarContent-root {
        color: ${(props: SnackbarLevelProps) =>
            props.success === 'true' ? '#000000' : '#FFFFFF'};
        border-top-left-radius: 10px;
        border-bottom-right-radius: 10px;
        background-color: ${(props: SnackbarLevelProps) =>
            props.success === 'true' ? '#48df9b' : '#d35c5c'};
    }

    .MuiSnackbarContent-message {
        width: 100%;
        display: flex;
        align-items: center;
    }
`;

const StyledMessageWrapper = styled.span`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiSvgIcon-root {
        padding: 0 0.4rem;
    }
`;

export interface SnackbarLevelProps {
    success: string;
}

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

export interface IProps {
    type: 'success' | 'failure' | null;
    message: string;
    onClose: () => void;
}

export const GlobalSnackbar: FC<IProps> = ({ type, message, onClose }) => {
    const [state, setState] = React.useState<{
        open: boolean;
        Transition: React.ComponentType<
            TransitionProps & {
                children: React.ReactElement<any, any>;
            }
        >;
    }>({
        open: false,
        Transition: SlideTransition,
    });

    useEffect(() => {
        if (message != null && message.length > 0) {
            setState({
                ...state,
                open: true,
            });
        }
    }, [message]);

    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        setState({
            ...state,
            open: false,
        });
        onClose();
    };

    const isSuccess = (type: 'success' | 'failure' | null): string => {
        return type === 'success' ? 'true' : 'false';
    };

    const determineIcon = (type: 'success' | 'failure' | null): ReactNode => {
        return type === 'success' ? (
            <DoneAllOutlinedIcon />
        ) : (
            <ErrorOutlineOutlinedIcon />
        );
    };

    const generateKey = () => {
        return new Date().toString();
    };

    return (
        <StyledSnackbar
            success={isSuccess(type)}
            open={state.open}
            onClose={handleClose}
            TransitionComponent={state.Transition}
            message={
                <StyledMessageWrapper>
                    <>{determineIcon(type)}</>
                    <>{message}</>
                </StyledMessageWrapper>
            }
            key={generateKey()}
            autoHideDuration={2000}
        />
    );
};
