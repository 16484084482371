import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import React, { FC, useEffect, useState } from 'react';
import { BasicTooltip } from '../../../../elements/_BasicComponents/BasicTooltip';
import { RulesTableRow } from './RulesTableRow';

interface EnhancedTableProps {
    numSelected: number;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, numSelected, rowCount } = props;
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <BasicTooltip placement="right" title="Select all">
                        <Checkbox
                            color="primary"
                            disabled={rowCount < 1}
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{ 'aria-label': 'select all ruls' }}
                        />
                    </BasicTooltip>
                </TableCell>
                <TableCell
                    key={"name"}
                    align={'left'}
                    padding={'none'}>
                    Rule name
                </TableCell>
                <TableCell
                    key={"edit"}
                    align={'right'}
                    padding={'none'}>
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

interface EnhancedTableToolbarProps {
    numSelected: number;
}

interface IProps {
    keyPhrases: string[]
    pagination: boolean,
    handleUpdateSmartRules: (newRules: string[], categoryId?: number) => void;
    isSmartRuleUnique: (smartRule: string, categoryId?: number) => boolean
    categoryId?: number
}

export const RulesTable: FC<IProps> = ({ keyPhrases, pagination, handleUpdateSmartRules, isSmartRuleUnique, categoryId }) => {
    const [rows, setRows] = useState<string[]>(keyPhrases);
    const [selected, setSelected] = useState<string[]>([]);
    const [addingNewRow, setAddingNewRow] = useState<boolean>(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(() => {
        setRows(keyPhrases);
    }, [keyPhrases])

    const handleDelete = () => {
        const rowsCopy = [...rows];
        selected.map(el => rowsCopy.splice(rowsCopy.indexOf(el), 1));
        setRows(rowsCopy);
        setSelected([]);
        handleUpdateSmartRules(rowsCopy, categoryId);
    }

    const handleAddRow = () => {
        setAddingNewRow(true);
    }

    const handleCancelNewRule = () => {
        setAddingNewRow(false);
    }

    const handleUpdateRow = (index: number, newValue: string) => {
        const rowsCopy = [...rows];
        if (index !== -1) {
            rowsCopy.splice(index, 1);
            rowsCopy.splice(index, 0, newValue);
        } else {
            rowsCopy.splice(0, 0, newValue);
        }
        setRows(rowsCopy);
        handleUpdateSmartRules(rowsCopy, categoryId);
        setAddingNewRow(false);
    }

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
        const { numSelected } = props;

        return (
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    ...(numSelected > 0 && {
                        bgcolor: (theme) =>
                            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                    }),
                }}
            >
                {numSelected > 0 ?
                    <>
                        <Typography
                            sx={{ flex: '1 1 100%' }}
                            color="inherit"
                            variant="subtitle1"
                            component="div"
                        >
                            {numSelected} selected
                        </Typography>
                        <BasicTooltip placement="left" title="Delete">
                            <IconButton onClick={handleDelete}>
                                <DeleteIcon />
                            </IconButton>
                        </BasicTooltip>
                    </>
                    :
                    <>
                        <Typography
                            sx={{ flex: '1 1 100%' }}
                            variant="h6"
                            id="tableTitle"
                            component="div"
                        >
                            Smart rules
                        </Typography>
                        <BasicTooltip placement="left" title="Add new rule">
                            <span>
                                <IconButton
                                    disabled={addingNewRow}
                                    onClick={handleAddRow}>
                                    <AddIcon />
                                </IconButton>
                            </span>
                        </BasicTooltip>
                    </>
                }
            </Toolbar>
        );
    };

    const generateTableBody = () => {
        return (
            //slice only if pagination is on
            rows.slice(pagination ? page * rowsPerPage : 0, pagination ? page * rowsPerPage + rowsPerPage : rows.length)
                .map((row, index) => {
                    return (
                        <RulesTableRow
                            key={`${row}-${index}`}
                            row={row}
                            index={index}
                            isItemSelected={isSelected(row)}
                            handleClick={handleClick}
                            handleUpdateRow={handleUpdateRow}
                            handleCancelNewRule={handleCancelNewRule}
                            isSmartRuleUnique={isSmartRuleUnique}
                            categoryId={categoryId}
                        />
                    );
                })
        );
    }

    return (
        <Box sx={{ width: '100%', margin: '30px 0' }}>
            <Paper sx={{ mb: 2, minHeight: '30vh', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)'}}>
                <EnhancedTableToolbar numSelected={selected.length} />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 500 }}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            onSelectAllClick={handleSelectAllClick}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {addingNewRow &&
                                <RulesTableRow
                                    row={''}
                                    index={-1}
                                    isItemSelected={false}
                                    handleClick={handleClick}
                                    handleUpdateRow={handleUpdateRow}
                                    handleCancelNewRule={handleCancelNewRule}
                                    isSmartRuleUnique={isSmartRuleUnique}
                                />
                            }
                            {generateTableBody()}
                            {emptyRows > 0 && (
                                <TableRow
                                    key={new Date().toISOString()}
                                    style={{
                                        height: 43 * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                            {rows.length === 0 && !addingNewRow &&
                                <TableRow
                                    key={new Date().toISOString()}
                                    style={{
                                        height: 43,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                {pagination &&
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                }
            </Paper>
        </Box>
    );
}