import moment from 'moment';
import {
    IDateRange,
    IDateRangeProps,
    IDateRangeState,
} from '../../utils/DataTypes';

export const prepareDateRangeRequestForMonthly = (
    activeStep: string //step: eg: 2021-02
): IDateRange => {
    return {
        dateFrom: `${activeStep}-01`,
        dateTo: `${activeStep}-${moment(activeStep).daysInMonth()}`,
    };
};

export const prepareDateRangeRequestForYearly = (
    activeStep: string
): IDateRange => {
    return {
        dateFrom: `${activeStep}-01-01`,
        dateTo: `${activeStep}-12-31`,
    };
};

export const prepareCurrentMonthDateRange = (): IDateRange => {
    const today = new Date();

    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const daysInMonth = String(moment(today).daysInMonth()).padStart(2, '0');

    return {
        dateFrom: `${year}-${month}-01`,
        dateTo: `${year}-${month}-${daysInMonth}`,
    };
};

export const prepareNewActiveDateRangeBasedOnStrategy = (
    dateRange: IDateRangeState,
    strategy: 'Yearly' | 'Monthly' | 'Custom'
): IDateRange => {
    if (strategy === 'Yearly') {
        return prepareDateRangeRequestForYearly(dateRange.yearly.display!);
    } else if (strategy === 'Monthly') {
        return prepareDateRangeRequestForMonthly(dateRange.monthly.display!);
    } else {
        return dateRange.custom;
    }
};

export const getActiveDatesIndex = (props: IDateRangeProps): number => {
    const settingIndex = props.values.indexOf(props.display!!);
    return settingIndex > 0 ? settingIndex : 0;
};

export const getActiveDate = (props: IDateRangeProps): string => {
    return props.values[getActiveDatesIndex(props)]!!;
};

export const isValidDate = (dateString: string) => {
    const timestamp = Date.parse(dateString);
    return !isNaN(timestamp);
};
