import styled from '@emotion/styled';
import { TextField } from '@mui/material';

export const AuthTextField = styled(TextField)`
    & label.Mui-focused {
        color: #0e7f63;
    }

    & .MuiOutlinedInput-root {
        & fieldset {
            border-color: #0e7f63;
        }

        &:hover fieldset {
            border-color: #b2bdc2;
        }

        &.Mui-focused fieldset {
            border-color: #21b890;
        }
    }
`;
