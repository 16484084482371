import styled from '@emotion/styled';
import FilePresentTwoToneIcon from '@mui/icons-material/FilePresentTwoTone';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { SelectChangeEvent } from '@mui/material/Select';
import React, { useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useGlobalState } from '../../store/store';
import { reloadDateRanges } from '../../store/thunks';
import { SupportedBank } from '../../utils/DataTypes';
import {
    apiUploadBankStatementCsvMultipart,
    fetchSupportedBanks,
} from '../../utils/api';
import {
    activeInputColor,
    baseColor,
    fontColor,
    textColor,
    textShadow,
} from '../../utils/constants';
import { StyledSection } from '../elements/_BasicComponents/Section';
import useGlobalSnackbar from '../elements/_Snackbar/useGlobalSnackbar';
import { isValidObject } from './categoriesList/table/dndTableUtils';
import { BasicDropdown } from '../elements/_BasicComponents/BasicDropdown';
import { BasicButton } from '../elements/_BasicComponents/BasicButton';

const FilePickerComponentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${textColor};
`;

const DropAreaWrapper = styled.div`
    width: 60vw !important;
    max-width: 800px;
    height: 40vh !important;
    max-height: 500px;
    border: 1px dashed ${fontColor};
    border-width: medium;
    border-radius: 10px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
`;

const InnerWrapper = styled.div`
    width: 99%;
    height: 99%;
    margin: auto;
    border-radius: 10px;

    font-size: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    .MuiSvgIcon-root {
        font-size: 15rem;
        position: absolute;
        top: 10%;
        opacity: 0.1;
    }

    :hover {
        opacity: 0.7;
        background-color: ${baseColor};
        transition: background-color 0.4s ease-in-out;
    }
`;

const IconTextWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const IconText = styled.div`
    font-weight: bold;
    color: ${fontColor};
    text-shadow: ${textShadow};
`;

const BottomText = styled.div`
    margin: 5px 0;
    font-size: 1rem;
    text-align: center;
`;

const HeaderWrapper = styled.h1`
    margin: 30px 0;
`;

const Wrapper = styled.div`
    margin: 30px 0;
`;

const StyledProgressButtonIcon = styled(RestartAltOutlinedIcon)`
    animation: spin-animation 1s infinite;
    animation-timing-function: linear;

    @keyframes spin-animation {
        0% {
            transform: rotate(360deg);
        }

        100% {
            transform: rotate(0deg);
        }
    }
`;

export const UploadStatement = () => {
    const { Snackbar, onSnackbarOpen } = useGlobalSnackbar();
    const [dateRange, setDateRange] = useGlobalState('dateRange');
    const [file, setFile] = useState<File | null>(null);
    const [supportedBanks, setSupportedBanks] = useState<SupportedBank[]>([]);
    const [chosenBank, setChosenBank] = useState<string>('');
    const [requestPending, setRequestPending] = useState<boolean>(false);

    useEffect(() => {
        fetchSupportedBanks().then(response => {
            setSupportedBanks(response);
        });
    }, []);

    const handleFileChange = (file: File) => {
        setFile(file);
    };

    const isSubmitButtonDisabled = (): boolean => {
        return file === null || chosenBank === '' || requestPending;
    };

    const handleUpload = async () => {
        if (isValidObject(file)) {
            setRequestPending(true);

            let formData = new FormData();
            formData.append('csvFile', file!);
            formData.append('bank', chosenBank);

            apiUploadBankStatementCsvMultipart(formData)
                .then(response => {
                    setRequestPending(false);
                    onSnackbarOpen({
                        message: `Successfully processed file! Processed ${response.processedPayments} transactions.`,
                        type: 'success',
                    });
                    reloadDateRanges().then(response => {
                        if (response) {
                            const clone = structuredClone(dateRange);
                            clone.monthly.values = response.monthly;
                            clone.yearly.values = response.yearly;
                            setDateRange(clone);
                        }
                    });
                    return;
                })
                .catch(error => {
                    handleOpenErrorSnackbar('Something went wrong!');
                })
                .finally(() => {
                    setRequestPending(false);
                });
        }
    };

    const handleOpenErrorSnackbar = (message: string) => {
        onSnackbarOpen({
            type: 'failure',
            message: message,
        });
    };

    const loadingIcon = () => {
        return <>{requestPending && <StyledProgressButtonIcon />}</>;
    };

    return (
        <StyledSection>
            <FilePickerComponentWrapper>
                <HeaderWrapper>Upload Your bank satement!</HeaderWrapper>
                <Wrapper>
                    <Box sx={{ width: 500 }}>
                        <BasicDropdown
                            key="bank-dropdown"
                            elements={supportedBanks}
                            title="Bank"
                            currentValue={chosenBank}
                            updateValue={setChosenBank}
                            keyExtractor={(value: SupportedBank) =>
                                value.bankName
                            }
                            valueExtractor={(value: SupportedBank) =>
                                value.bankDisplayName
                            }
                        />
                    </Box>
                </Wrapper>
                <Wrapper>
                    <FileUploader
                        handleChange={handleFileChange}
                        onSizeError={(file: File) =>
                            handleOpenErrorSnackbar(
                                'File size mush be lower than 10MB'
                            )
                        }
                        onTypeError={(err: any) =>
                            handleOpenErrorSnackbar(`Type error: ${err}`)
                        }
                        name="csvFile"
                        types={['csv']}
                        icon={loadingIcon()}
                        children={
                            <DropAreaWrapper>
                                <InnerWrapper>
                                    <IconTextWrapper>
                                        <FilePresentTwoToneIcon />
                                        <IconText>
                                            {' '}
                                            {file
                                                ? file.name
                                                : 'Upload Your file here!'}
                                        </IconText>
                                    </IconTextWrapper>
                                    <BottomText>
                                        {' '}
                                        Compatible only .csv
                                    </BottomText>
                                </InnerWrapper>
                            </DropAreaWrapper>
                        }
                        maxSize={10}
                    />
                </Wrapper>
                <BasicButton
                    onSubmit={() => handleUpload()}
                    onClick={() => handleUpload()}
                    startIcon={loadingIcon()}
                    disabled={isSubmitButtonDisabled()}
                >
                    Upload
                </BasicButton>
            </FilePickerComponentWrapper>
            <Snackbar />
        </StyledSection>
    );
};
