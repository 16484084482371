import styled from '@emotion/styled';
import { InputAdornment, Stack } from '@mui/material';
import React, { FC } from 'react';
import { borderRadius } from '../../../utils/constants';
import { BasicTextField } from '../../elements/_BasicComponents/BasicTextField';
import { StyledSection } from '../../elements/_BasicComponents/Section';
import SearchIcon from '@mui/icons-material/Search';

const StyledStack = styled(Stack)`
	width: 95%;

	.MuiAutocomplete-root {
		border-radius: ${borderRadius}px;
	}
`;

interface IFilterBarProps {
	setFilter: (filter: string) => void;
}

export const FilterBar: FC<IFilterBarProps> = ({ setFilter }) => {
	const [value, setValue] = React.useState<string>('');

	const handleFilterChange = (newValue: string | null) => {
		if (newValue !== null) {
			setValue(newValue);
			setFilter(newValue);
		}
	};

	return (
		<StyledSection>
			<StyledStack>
				<BasicTextField
					value={value}
					onChange={handleFilterChange}
					label="Filter payments"
					placeholder="e.g. Uber eats.."
					startAdornment={
						<InputAdornment position="start">
							<SearchIcon />
						</InputAdornment>
					}
				/>
			</StyledStack>
		</StyledSection>
	);
};
