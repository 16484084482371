import React, { FC, ReactNode } from "react";
import Button from '@mui/material/Button';
import styled from "@emotion/styled";
import { fontColor } from "../../../utils/constants";

const StyledButton = styled(Button)`
    font-size: 4rem;
    color: ${fontColor};
    .MuiButton-disableElevation {
        color: grey;
    }

    .MuiSvgIcon-root {
        font-size: 4rem;
    }
`;

interface IProps {
    onClick: () => void,
    disabled: boolean,
    children: ReactNode
}

export const SliderButton: FC<IProps> = ({ onClick, disabled, children }) => {

    return (
        <StyledButton color="inherit" onClick={onClick} disabled={disabled}>
            {children}
        </StyledButton>
    );
}