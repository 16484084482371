import styled from '@emotion/styled';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { ResponsiveLine } from '@nivo/line';
import React, { useEffect, useState } from 'react';
import { ILineChartEntry, IStatementSummary } from '../../utils/DataTypes';
import { fetchExpenseData, fetchIncomeData } from '../../utils/api';
import { fontColor } from '../../utils/constants';
import { NoDataYet } from '../elements/NoDataYet';
import { StyledSection } from '../elements/_BasicComponents/Section';
import {
	AXIS_BOTTOM,
	AXIS_LEFT,
	ANALYSE_LEGENDS,
	mapStatementSummaryToChartData,
	mapStatementSummaryToMarks,
} from './analyse/AnalyseUtils';

const SectionForGraph = styled(StyledSection)`
	display: block;
	box-sizing: border-box;
`;

export const SliderWrapper = styled.div`
	width: 50vw;
	padding: 2%;

	.PrivateValueLabel-offset-3 {
		left: calc(-50% - 20px) !important;
		top: -65px !important;
	}

	.PrivateValueLabel-circle-4 {
		width: 65px !important;
		height: 65px !important;
	}

	.MuiSlider-root {
		color: ${fontColor};
	}
`;

export const StyledLineChart = styled.div`
	height: 55vh;
	margin: 2vh 2vw;
`;

export const CenteredWrapper = styled.div`
	position: relative;
	text-align: center;
`;

export const StyledSlider = styled(Slider)`
	color: #ad2828;
`;

export interface IMark {
	value: number;
	label: string;
}

export const AnalysePage = () => {
	const [monthSliderMarks, setMonthSliderMarks] = useState<IMark[]>([]);
	const [monthLimit, setMonthLimit] = useState<number[]>([0, 1]);
	const [incomeStatementSummary, setIncomeStatementSummary] = useState<IStatementSummary>();
	const [expenseStatementSummary, setExpenseStatementSummary] = useState<IStatementSummary>();
	const [chartData, setChartData] = useState<ILineChartEntry[]>([]);
	const [error, setError] = useState<boolean>(false);

	useEffect(() => {
		fetchIncomeData()
			.then((value) => {
				setIncomeStatementSummary(value);
				setError(false);
			})
			.catch((error) => {
				console.error(`Unable to fetch income entries from BE due to: ${error}`);
				setError(true);
			});

		fetchExpenseData()
			.then((value) => {
				setExpenseStatementSummary(value);
				setError(false);
			})
			.catch((error) => {
				console.error(`Unable to fetch expense entries from BE due to: ${error}`);
				setError(true);
			});
	}, []);

	useEffect(() => {
		var monthSliderMarks = mapStatementSummaryToMarks(incomeStatementSummary);
		setMonthSliderMarks(monthSliderMarks);
	}, [incomeStatementSummary]);

	useEffect(() => {
		setMonthLimit([0, monthSliderMarks.length - 1]);
	}, [monthSliderMarks]);

	useEffect(() => {
		if (incomeStatementSummary != undefined && expenseStatementSummary != undefined) {
			setChartData([
				mapStatementSummaryToChartData(incomeStatementSummary, monthLimit),
				mapStatementSummaryToChartData(expenseStatementSummary, monthLimit),
			]);
		}
	}, [monthLimit]);

	const isDataPresent = (): boolean =>
		!error && (incomeStatementSummary !== null || expenseStatementSummary !== null);

	const handleChange = (event: any, newValue: number[] | number) => {
		if (Array.isArray(newValue)) setMonthLimit(newValue);
		else setMonthLimit([0, newValue]);
	};

	function valueLabel(index: number) {
		if (monthSliderMarks != null && monthSliderMarks[index] != null) return monthSliderMarks[index].label;
		else return '';
	}

	return isDataPresent() ? (
		<CenteredWrapper>
			<StyledSection>
				<h2>Budget specification over time</h2>
				<SliderWrapper>
					<Typography id="discrete-slider-custom" gutterBottom>
						Choose Your custom date range
					</Typography>
					<Slider
						value={monthLimit}
						onChangeCommitted={handleChange}
						valueLabelDisplay="auto"
						marks={monthSliderMarks}
						size={monthSliderMarks.length > 10 ? 'small' : 'medium'}
						step={1}
						min={0}
						max={monthSliderMarks.length - 1}
						valueLabelFormat={valueLabel}
					/>
				</SliderWrapper>
			</StyledSection>
			<SectionForGraph>
				<StyledLineChart>
					<ResponsiveLine
						data={chartData}
						margin={{ top: 30, right: 110, bottom: 60, left: 70 }}
						xScale={{ type: 'point' }}
						yScale={{ type: 'linear', stacked: false }}
						colors={{ scheme: 'paired' }}
						yFormat=" >-,.2f"
						lineWidth={3}
						curve="monotoneX"
						enableArea={true}
						areaBlendMode="normal"
						axisBottom={AXIS_BOTTOM}
						axisLeft={AXIS_LEFT}
						pointSize={10}
						pointColor={{ theme: 'background' }}
						pointBorderWidth={2}
						pointBorderColor={{ from: 'serieColor' }}
						pointLabelYOffset={-12}
						useMesh={true}
						// sliceTooltip={({ slice }) => {
						//     return (
						//         <>
						//             {slice.points[0].data.xFormatted +
						//                 ': ' +
						//                 slice.points[0].data.yFormatted}
						//         </>
						//     );
						// }} //TODO: add better linechart tooltip
						enableSlices="x"
						legends={ANALYSE_LEGENDS}
						motionConfig="stiff"
					/>
				</StyledLineChart>
			</SectionForGraph>
		</CenteredWrapper>
	) : (
		<NoDataYet />
	);
};
