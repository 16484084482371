import styled from '@emotion/styled';
import {
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    Droppable,
    DroppableProvided,
    DroppableStateSnapshot,
} from '@hello-pangea/dnd';
import React, { FC } from 'react';
import { ICategoryColumn } from '../../../../utils/DataTypes';
import {
    borderRadius,
    firstGradientColor,
    secondGradientColor,
} from '../../../../utils/constants';
import { Tile } from '../tile/Tile';
import { ColumnHeader } from './ColumnHeader';

const Container = styled.div`
    margin: 10px;
`;

const TileList = styled.div<DraggableProps>`
    padding: 5px;
    margin-top: 5px;
    background-image: ${(props: DraggableProps) =>
        props.draggingOver
            ? `linear-gradient(${firstGradientColor}, ${firstGradientColor})`
            : `linear-gradient(${firstGradientColor}, ${secondGradientColor})`};
    display: flex;
    flex-direction: column;
    width: 300px;
    min-height: 400px;
    border-bottom-left-radius: ${borderRadius}px;
    border-bottom-right-radius: ${borderRadius}px;
    box-shadow: 5px 10px 10px #00000040;
`;

interface DraggableProps {
    draggingOver: boolean;
}

interface IProps {
    category: ICategoryColumn;
    index: number;
    handleDeleteCategory: (category: ICategoryColumn) => void;
    reloadTable: () => void;
    isCategoryNameUnique: (categoryName: string, categoryId: number) => boolean;
    isSmartRuleUnique: (smartRule: string, categoryId?: number) => boolean;
    handleUpdateSmartRules: (newRules: string[], categoryId?: number) => void;
    paymentsFilter: string;
}

export const Column: FC<IProps> = ({
    category,
    index,
    handleDeleteCategory,
    reloadTable,
    isCategoryNameUnique,
    isSmartRuleUnique,
    handleUpdateSmartRules,
    paymentsFilter
}) => {
    return (
        <Draggable
            key={`dragging-column-${category.id}`}
            draggableId={`${category.categoryName}`}
            index={index}
        >
            {(
                provided: DraggableProvided,
                snapshot: DraggableStateSnapshot
            ) => (
                <Container ref={provided.innerRef} {...provided.draggableProps}>
                    <ColumnHeader
                        category={category}
                        handleDeleteCategory={handleDeleteCategory}
                        isDraggingOver={Boolean(snapshot.draggingOver)}
                        provided={provided}
                        reloadTable={reloadTable}
                        isCategoryNameUnique={isCategoryNameUnique}
                        isSmartRuleUnique={isSmartRuleUnique}
                        handleUpdateSmartRules={handleUpdateSmartRules}
                    />

                    <Droppable
                        direction="vertical"
                        key={`droppable-column-${category.id}`}
                        droppableId={category.droppableId}
                        type="payment"
                    >
                        {(
                            provided: DroppableProvided,
                            snapshot: DroppableStateSnapshot
                        ) => (
                            <TileList
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                draggingOver={snapshot.isDraggingOver}
                            >
                                {category.paymentRecords
                                .filter((paymentRecord) => paymentRecord.title.toLocaleLowerCase().includes(paymentsFilter.toLocaleLowerCase()))
                                .map(
                                    (paymentRecord, index) => (
                                        <Tile
                                            reloadTable={reloadTable}
                                            paymentRecord={paymentRecord}
                                            index={index}
                                            key={paymentRecord.draggableId}
                                        />
                                    )
                                )}

                                {provided.placeholder}
                            </TileList>
                        )}
                    </Droppable>
                </Container>
            )}
        </Draggable>
    );
};
