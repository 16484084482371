import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, FormControl, FormHelperText } from '@mui/material';
import { green } from '@mui/material/colors';
import { error } from 'console';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { z } from 'zod';
import { REGISTER_PATH, RESET_PASSWORD_PATH } from '../../../utils/pagePaths';
import { AuthTextField } from './AuthTextField';
import { LoginFormWrapper, RedirectSection } from './LoginWrapper';

const formSchema = z.object({
	password: z.string().min(1, 'Please enter the password'),
	email: z.string().min(1, 'Please specify an email').email('Please specify a valid email')
});
type FormFields = z.infer<typeof formSchema>;

interface IProps {
	handleLogin: (email: string, password: string) => void;
	redirecteQueryParam: string;
}

export const LoginForm: React.FC<IProps> = ({ handleLogin, redirecteQueryParam }) => {
	const { control, handleSubmit } = useForm<FormFields>({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		resolver: zodResolver(formSchema),
		defaultValues: {
			email: '',
			password: ''
		}
	});

	return (
		<LoginFormWrapper
			className="login-form"
			noValidate
			onSubmit={handleSubmit((data) => handleLogin(data.email, data.password))}
		>
			<h1>Sign In</h1>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: '1rem',
					p: '1rem',
					width: '30rem',
					accentColor: green
				}}
			>
				<Controller
					name="email"
					control={control}
					render={({ field: { value, onChange, onBlur, ref }, fieldState: { error } }) => (
						<FormControl>
							<AuthTextField
								name="email"
								label="Email"
								placeholder="example@mail.com"
								required
								inputRef={ref}
								value={value}
								onChange={onChange}
								onBlur={onBlur}
								error={Boolean(error)}
							/>
							<FormHelperText
								sx={{
									color: 'error.main',
									paddingBottom: '0.25rem'
								}}
							>
								{error?.message ?? ''}
							</FormHelperText>
						</FormControl>
					)}
				/>
				<Controller
					name="password"
					control={control}
					render={({ field: { value, onChange, onBlur, ref }, fieldState: { error } }) => (
						<FormControl>
							<AuthTextField
								name="password"
								label="Password"
								type="password"
								required
								inputRef={ref}
								value={value}
								onChange={onChange}
								onBlur={onBlur}
								error={Boolean(error)}
							/>
							<FormHelperText
								sx={{
									color: 'error.main',
									paddingBottom: '0.25rem'
								}}
							>
								{error?.message ?? ''}
							</FormHelperText>
							<Link
								className="forgot-password styled-link"
								to={`${RESET_PASSWORD_PATH}${redirecteQueryParam}`}
							>
								Forgot password?
							</Link>
							<Button disabled={Boolean(error)} type="submit">
								Login
							</Button>
						</FormControl>
					)}
				/>
			</Box>
			<RedirectSection>
				<span>
					Not a member?
					<Link className="styled-link" to={`${REGISTER_PATH}${redirecteQueryParam}`}>
						Create Your Account
					</Link>
				</span>
			</RedirectSection>
		</LoginFormWrapper>
	);
};
