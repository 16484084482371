import styled from '@emotion/styled';
import React, { FC, useState } from 'react';
import { ICategory } from '../../../../utils/DataTypes';
import { BasicButtonGroup } from '../../../elements/_BasicComponents/BasicButtonGroup';
import { BasicTextField } from '../../../elements/_BasicComponents/BasicTextField';

const ItalicTitle = styled.div`
    font-style: italic;
    text-align: center;
    font-size: 2rem;
    cursor: pointer;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;

    > * + * {
        margin-left: 0.5rem;
    }
`;

export const isNewNameInvalid = (newName: string) => {
    return (
        newName.length === 0 ||
        newName.length > 30 ||
        (newName.length > 0 && newName.trimEnd().length < 1)
    );
};

export const determineLabelForCategoryNameInput = (
    newName: string,
    nameTaken: boolean
): string => {
    if (newName.length > 30) {
        return 'Max 30 characters';
    }
    if (nameTaken) {
        return 'Name already taken';
    }
    if (newName.length > 0 && newName.trimEnd().length < 1) {
        return 'Invalid name';
    }
    return 'Category name';
};

interface IProps {
    category: ICategory;
    handleUpdateCategory: (newCategory: ICategory) => void;
    isCategoryNameUnique: (categoryName: string, categoryId: number) => boolean;
}

export const EditCategoryNameHeading: FC<IProps> = ({
    category,
    handleUpdateCategory,
    isCategoryNameUnique,
}) => {
    const [newName, setNewName] = useState<string>(category.categoryName);
    const [editName, setEditName] = useState<boolean>(false);
    const [newNameTaken, setNewNameTaken] = useState<boolean>(false);

    const handleChangeNameSubmit = (newName: string) => {
        const categoryCopy = { ...category };
        categoryCopy.categoryName = newName;
        handleUpdateCategory(categoryCopy);
        setEditName(false);
    };

    const handleNameChangeReject = () => {
        setNewName(category.categoryName);
        setEditName(false);
    };

    const handleNameChange = (newString: string) => {
        setNewName(newString);
        setNewNameTaken(!isCategoryNameUnique(newString, category.id));
    };

    return (
        <>
            {editName ? (
                <Wrapper>
                    <BasicTextField
                        value={newName}
                        error={isNewNameInvalid(newName) || newNameTaken}
                        label={determineLabelForCategoryNameInput(
                            newName,
                            newNameTaken
                        )}
                        onChange={event => handleNameChange(event)}
                    />
                    <BasicButtonGroup
                        acceptOnClick={() => handleChangeNameSubmit(newName)}
                        rejectOnClick={handleNameChangeReject}
                    />
                </Wrapper>
            ) : (
                <ItalicTitle onClick={() => setEditName(true)}>{category.categoryName}</ItalicTitle>
            )
            }
        </>
    );
};
