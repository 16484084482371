import React, { FC } from 'react';
import { Button } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import styled from '@emotion/styled';
import { IDateTimeStrategy, STRATEGIES } from '../DateStrategyPicker';

const IconButton = styled(Button)`
    width: 50px;
    height: 50px;
    min-width: 50px;
    border-radius: 50px;
`;

const ColorlibConnector = styled(StepConnector)`
    top: 22px;
`;

const StyledStepperIcon = styled.div<IconProp>`
    background-color: #ccc;
    z-index: 1;
    color: #fff;
    width: 50px;
    height: 50px;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    background-image: ${(props: IconProp) =>
        props.active
            ? 'linear-gradient( 136deg, #65F4CD 20%, #4799E9 110%)'
            : ''};
    box-shadow: ${(props: IconProp) =>
        props.active ? '0 4px 10px 0 rgba(0,0,0,.25)' : 'none'};
`;

interface IconProp {
    active: boolean | undefined;
}

interface IProps {
    activeStrategy: IDateTimeStrategy;
    handleStrategyChange: (strategy: IDateTimeStrategy) => void;
}

export const StrategyStepper: FC<IProps> = ({
    activeStrategy,
    handleStrategyChange,
}) => {
    const getStrategyByKey = (key: number): IDateTimeStrategy => {
        return STRATEGIES.find((value) => value.key === key)!;
    };

    function ColorlibStepIcon(props: StepIconProps) {
        const { active, icon } = props;
        const strategy = getStrategyByKey(Number.parseInt(String(icon)));

        return (
            <StyledStepperIcon active={active}>
                <IconButton
                    color="inherit"
                    onClick={() => handleStrategyChange(strategy)}
                >
                    {strategy.icon}
                </IconButton>
            </StyledStepperIcon>
        );
    }

    return (
        <Stepper
            alternativeLabel
            activeStep={activeStrategy.key - 1}
            connector={<ColorlibConnector />}
        >
            {STRATEGIES.map((step) => (
                <Step key={step.action}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                        {step.action}
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    );
};
