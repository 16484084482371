import isEqual from 'lodash.isequal';
import React, { useCallback, useEffect, useState } from 'react';
import { useGlobalState } from '../../store/store';
import { fetchStatisticsData } from '../../store/thunks';
import { ICategory, IDateRangeState } from '../../utils/DataTypes';
import DateStrategyPicker from '../elements/DateStrategyPicker';
import ExpenseRevenuePicker from '../elements/ExpenseRevenuePicker';
import { initCategoriesKey } from './categoriesList/table/dndTableUtils';
import { PieChart } from './insights/PieChart';

export const InsightsPage = () => {
	//globals
	const [globalTransactionType, setGlobalTransactionType] = useGlobalState('transactionType');
	const [globalDateRange, setGlobalDateRange] = useGlobalState('dateRange');
	const [globalCategories, setGlobalCategories] = useGlobalState(initCategoriesKey(globalTransactionType));

	//locals
	const [transactionType, setTransactionType] = useState<'EXPENSE' | 'REVENUE'>(globalTransactionType);
	const [dateRange, setDateRange] = useState<IDateRangeState>(globalDateRange);
	const [categories, setCategories] = useState<ICategory[]>(globalCategories);

	const handleChangeTransactionType = useCallback(
		(newTransactionType: 'EXPENSE' | 'REVENUE') => {
			if (isEqual(newTransactionType, transactionType)) {
				return;
			}
			setTransactionType(newTransactionType);
			setGlobalTransactionType(newTransactionType);
		},
		[transactionType],
	);

	const handleChangeDateRange = useCallback(
		(newDateRange: IDateRangeState) => {
			if (isEqual(newDateRange, dateRange)) {
				return;
			}
			setDateRange(newDateRange);
			setGlobalDateRange(newDateRange);
		},
		[dateRange],
	);

	const handleChangeCategories = (newCategories: ICategory[]) => {
		console.log('changing categorie');
		if (isEqual(newCategories, categories)) {
			return;
		}
		setCategories(newCategories);
		setGlobalCategories(newCategories !== null ? newCategories : []);
	};

	// UseEffect that should fetch API categories on parameters change
	useEffect(() => {
		const abortController = new AbortController();
		console.log('fetching data');
		fetchStatisticsData(dateRange.active, transactionType, handleChangeCategories, abortController.signal);

		return () => {
			// abortController.abort();
		};
	}, [dateRange.active, transactionType]);

	return (
		<>
			<ExpenseRevenuePicker
				key={transactionType}
				transactionType={transactionType}
				changeTransactionType={handleChangeTransactionType}
			/>
			<DateStrategyPicker key={'1'} dateRange={dateRange} setDateRange={handleChangeDateRange} />
			<PieChart transactionType={transactionType} categories={categories} />
		</>
	);
};
InsightsPage.whyDidYouRender = true;
