import styled from '@emotion/styled';
import React from 'react';

const CenteredWrapper = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const RoundImage = styled.img`
    border-radius: 20px;
    max-width: 50%;
    min-width: 30%;
    height: auto;
`

export const MainDashboard = () => {

    return (
        <CenteredWrapper>
            <h1>Main Dashboard</h1>
            <p>Content in that page coming sooooooooon :) </p>
            <RoundImage src='https://media1.tenor.com/images/e22f385ad98ef6fca860b45a621f46be/tenor.gif?itemid=10069195'/>
        </CenteredWrapper>
    );
};
