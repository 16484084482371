import React, { FC } from 'react';
import { ICategory } from '../../../../utils/DataTypes';
import { RulesTable } from './rules/RulesTable';
import { BasicButton } from '../../../elements/_BasicComponents/BasicButton';
import { BasicModal } from '../../../elements/_ModalComponents/BasicModal';
import { EditCategoryNameHeading } from './EditCategoryNameHeading';

interface IProps {
    open: boolean;
    category: ICategory;
    handleCloseDialog: () => void;
    handleUpdateCategory: (newCategory: ICategory) => void;
    handleUpdateSmartRules: (newRules: string[], categoryId?: number) => void;
    isCategoryNameUnique: (categoryName: string, categoryId: number) => boolean;
    isSmartRuleUnique: (smartRule: string, categoryId?: number) => boolean;
}

export const CategoryDetailsDialog: FC<IProps> = ({
    open,
    category,
    handleCloseDialog,
    handleUpdateCategory,
    isCategoryNameUnique,
    handleUpdateSmartRules,
    isSmartRuleUnique
}) => {

    return (
        <BasicModal
            open={open}
            onClose={handleCloseDialog}
            heading={
                <EditCategoryNameHeading
                    key={`categoryDialogHeader-${category.id}`}
                    category={category}
                    handleUpdateCategory={handleUpdateCategory}
                    isCategoryNameUnique={isCategoryNameUnique}
                />
            }
            negativeButton={
                <BasicButton onClick={handleCloseDialog}>Close</BasicButton>
            }
        >
            <RulesTable
                keyPhrases={category.keyPhrases}
                handleUpdateSmartRules={handleUpdateSmartRules}
                pagination={true}
                categoryId={category.id}
                isSmartRuleUnique={isSmartRuleUnique}
            />
        </BasicModal>
    );
};
