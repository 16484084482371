import React from 'react';
import styled from '@emotion/styled';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { borderRadius } from '../../../../utils/constants';
import { BasicTooltip } from '../../../elements/_BasicComponents/BasicTooltip';

const StyledStack = styled(Stack)`
    .MuiSkeleton-root {
        background-color: #d8d5d5;
    }
`;
const StyledTopSkeleton = styled(Skeleton)`
    border-top-left-radius: ${borderRadius}px;
    border-top-right-radius: ${borderRadius}px;
`;

const StyledBottomSkeleton = styled(Skeleton)`
    border-bottom-left-radius: ${borderRadius}px;
    border-bottom-right-radius: ${borderRadius}px;
`;

export const CategorySkeleton = () => {
    return (
        <BasicTooltip
            title='Add Your first Category!'
            content='To do so check the bottom right plus sign!'
            placement="right"
        >
            <StyledStack spacing={0.6} paddingTop={1.4}>
                <StyledTopSkeleton
                    variant="rectangular"
                    width={310}
                    height={88}
                />
                <StyledBottomSkeleton
                    variant="rectangular"
                    width={310}
                    height={410}
                />
            </StyledStack>
        </BasicTooltip>
    );
};
