import React, { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { BASE_PATH } from '../../utils/pagePaths';
import { AUTH_TOKEN_PARAM, getSessionParam } from './AuthSystemUtils';

interface IProps {
    children: ReactNode
}

export const PublicRouter: FC<IProps> = ({ children }) => {
    const auth = getSessionParam(AUTH_TOKEN_PARAM);

    return (
        <>
            {auth ? <Navigate to={BASE_PATH} /> : children}
        </>
    );
}