
export const baseColor = "#CDF0EA";
export const dragColor = "#CAF7E3";
export const elementColor = "#77c4b7";
export const hoverColor = "#18f0d31d";
export const activeInputColor= "#0e7a6899";
export const errorInputColor= "#d32f2f";
export const borderRadius = 8;
export const textColor = "#666"
export const fontColor = "#29d4b9"
export const textShadow = "1px 1px #ddd";
export const firstGradientColor = "#65F4CD80";
export const secondGradientColor = "#4799E980";
