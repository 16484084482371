import { ISnackbarProps } from '../components/elements/_Snackbar/useGlobalSnackbar';
import { determineIndexForUnassignedCategory } from '../components/pages/categoriesList/table/dndTableUtils';
import {
	CreateSmartRuleResponse,
	DomainErrorResponse,
	IApiDateRange,
	ICategory,
	IDateRange,
	TransactionType
} from '../utils/DataTypes';
import {
	apiAddSmartRule,
	apiEditSmartRules,
	fetchDateRanges,
	fetchRevenueUnassignedPayments,
	fetchStatisticsCategoriesData
} from '../utils/api';

export const addSmartRuleAndReloadTableThunk = (
	targetCategoryId: number,
	ruleContent: string,
	transactionType: TransactionType,
	onSnackbarOpen: (snackbarProps: ISnackbarProps) => void
): Promise<void> => {
	return apiAddSmartRule(targetCategoryId, ruleContent, TransactionType[transactionType])
		.then((response: CreateSmartRuleResponse) => {
			const message =
				'Altered smart rules!' +
				(response.movedPayments > 0 ? ' Moved ' + response.movedPayments + ' payment records.' : '');
			onSnackbarOpen({
				message: message,
				type: 'success'
			});
		})
		.catch((ex: any) => ex.response.json())
		.then((errorBody: DomainErrorResponse) => {
			if (!errorBody) {
				return;
			}
			onSnackbarOpen({
				message: `${errorBody.message}`,
				type: 'failure'
			});
		});
};

export const editSmartRulesAndReloadTableThunk = (
	targetCategoryId: number,
	newSmartRules: string[],
	transactionType: TransactionType,
	onSnackbarOpen: (snackbarProps: ISnackbarProps) => void
): Promise<void> => {
	return apiEditSmartRules(targetCategoryId, newSmartRules, TransactionType[transactionType])
		.then((response: CreateSmartRuleResponse) => {
			const message =
				'Altered smart rules!' +
				(response.movedPayments > 0 ? ' Moved ' + response.movedPayments + ' payment records.' : '');
			onSnackbarOpen({
				message: message,
				type: 'success'
			});
		})
		.catch((ex: any) => ex.response.json())
		.then((errorBody: DomainErrorResponse) => {
			if (!errorBody) {
				return;
			}
			onSnackbarOpen({
				message: `${errorBody.message}`,
				type: 'failure'
			});
		});
};

export const fetchStatisticsData = async (
	dateRangeActive: IDateRange,
	transactionType: 'EXPENSE' | 'REVENUE',
	updateStateFunc: (value: ICategory[]) => void,
	abortSignal?: AbortSignal
) => {
	const [apiCategories, unassigned] = await Promise.all([
		fetchStatisticsCategoriesData(dateRangeActive, TransactionType[transactionType], abortSignal).then(
			(response) => {
				if (!Array.isArray(response)) {
					return [] as ICategory[];
				}
				return response;
			}
		),
		fetchRevenueUnassignedPayments(dateRangeActive, TransactionType[transactionType], abortSignal).then(
			(response) => response
		)
	]);

	if (Array.isArray(apiCategories) && abortSignal?.aborted) {
		return;
	}
	const combined = [...apiCategories];
	const indexForUnassigned = determineIndexForUnassignedCategory(apiCategories);
	unassigned.columnOrder = indexForUnassigned;
	combined.splice(indexForUnassigned, 0, unassigned);
	updateStateFunc(combined);
};

export async function reloadDateRanges(): Promise<IApiDateRange | undefined> {
	return await fetchDateRanges().then((response) => {
		if (response) {
			return response;
		}
		return Promise.resolve(undefined);
	});
}
