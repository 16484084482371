import { createGlobalState } from 'react-hooks-global-state';
import { prepareCurrentMonthDateRange } from '../components/elements/utils';
import { ICategory, IDateRange, IDateRangeState } from '../utils/DataTypes';

const initialDateRange: IDateRange = prepareCurrentMonthDateRange();

const initialDateRangeState: IDateRangeState = {
    active: initialDateRange,
    custom: initialDateRange,
    yearly: {
        values: [],
        display: null,
    },
    monthly: {
        values: [],
        display: null,
    },
    strategy: 'Monthly',
};

export const { useGlobalState } = createGlobalState<{
    expenseCategories: ICategory[];
    revenueCategories: ICategory[];
    dateRange: IDateRangeState;
    smartRulesEnabled: boolean;
    transactionType: 'EXPENSE' | 'REVENUE';
}>({
    expenseCategories: [],
    revenueCategories: [],
    dateRange: initialDateRangeState,
    smartRulesEnabled: true,
    transactionType: 'EXPENSE',
});
