import React, { FC } from "react";
import styled from '@emotion/styled';

export const StyledSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-shadow: 5px 5px 20px #bbb;
    padding: 2% 0;
    border-radius: 10px;
    margin: 0 0 30px;
`
export const Section = (props: any) => {
    return (
        <>
            <StyledSection></StyledSection>
        </>
    );
}