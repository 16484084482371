import React from 'react';

export interface IToken {
    accessToken: string;
    refreshToken: string;
}

export interface IRoute {
    path: string;
    element: React.ReactElement;
}

export interface ICategory {
    id: number;
    keyPhrases: string[];
    categoryName: string;
    transactionType: string;
    columnOrder: number;
    paymentRecords: IPaymentRecord[];
}

export interface ICategoryColumn {
    id: number;
    droppableId: string;
    keyPhrases: string[];
    categoryName: string;
    columnOrder: number;
    transactionType: string;
    paymentRecords: IPaymentTile[];
}

export interface ICreateCategory {
    categoryName: string;
    transactionType: 'REVENUE' | 'EXPENSE';
    keyPhrases: string[];
}

export interface IPaymentRecord {
    id: number;
    transactionType: 'REVENUE' | 'EXPENSE';
    paymentReceiver: string;
    amount: number;
    title: string;
    paymentDate: string;
    bookingDate: string;
    targetAccountNumber?: number;
}

export interface ICreatePaymentRecord {
    transactionType: 'REVENUE' | 'EXPENSE';
    paymentReceiver?: string | null;
    amount: number;
    title: string;
    paymentDate: string;
    bookingDate?: string | null;
    targetAccountNumber?: number | null;
}

export interface IUpdatePaymentRecord {
    id: number;
    transactionType: 'REVENUE' | 'EXPENSE';
    paymentReceiver?: string | null;
    amount: number;
    title: string;
    paymentDate: string;
    bookingDate?: string | null;
    targetAccountNumber?: number | null;
}

export interface IPaymentTile {
    id: number;
    draggableId: string;
    transactionType: 'REVENUE' | 'EXPENSE';
    paymentReceiver: string;
    amount: number;
    title: string;
    paymentDate: string;
    bookingDate: string;
    targetAccountNumber?: number;
}

export interface IPieChartCategory {
    id: string;
    label: string;
    value: string;
    color: string;
}

export interface IPieChartFill {
    id: string;
    match: { id: string };
}

export interface IStatementSummary {
    transactionType: 'REVENUE' | 'EXPENSE';
    dateRange: string[];
    monthStatements: IMonthStatement[];
}

export interface IMonthStatement {
    date: string;
    transactionSum: number;
    transactions: IIncomeEntry[];
}

export interface IIncomeEntry {
    transactionType: 'REVENUE' | 'EXPENSE';
    paymentReceiver: string;
    amount: number;
    title: string;
    paymentDate: Date;
    bookingDate: Date;
    targetAccountNumber: number;
}

export interface ILineChartEntry {
    id: string;
    color: string;
    data: ILineChartEntryData[];
}

export interface ILineChartEntryData {
    x: string;
    y: number;
}

export type IGlobalState = {
    categories: ICategoriesState;
};

export type IApiDateRange = {
    yearly: string[];
    monthly: string[];
};

export type IDateRange = {
    dateFrom: string;
    dateTo: string;
};

export type IDateRangeProps = {
    values: string[];
    display: string | null;
};

export type IDateRangeState = {
    active: IDateRange;
    custom: IDateRange;
    yearly: IDateRangeProps;
    monthly: IDateRangeProps;
    strategy: 'Yearly' | 'Monthly' | 'Custom';
};

export type ICategoriesState = {
    expenseCategories: ICategory[];
    revenueCategories: ICategory[];
    dateRange: IDateRangeState;
    smartRulesEnabled: boolean;
    transactionType: 'EXPENSE' | 'REVENUE';
};

export enum TransactionType {
    EXPENSE = 0,
    REVENUE = 1,
}

export type SupportedBank = {
    bankName: string;
    bankDisplayName: string;
};

export type CsvUploadResponse = {
    processedPayments: number;
};

export type CreateSmartRuleResponse = {
    movedPayments: number;
};
export type DomainErrorResponse = {
    message: string;
};
