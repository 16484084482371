import ky, { ResponsePromise } from 'ky';
import {
    AUTH_TOKEN_PARAM,
    REFRESH_TOKEN_PARAM,
    USER_EMAIL_PARAM,
    getSessionParam,
    handleRefreshTokenSuccess,
    useHandleLogout,
} from '../components/auth/AuthSystemUtils';
import {
    CreateSmartRuleResponse,
    CsvUploadResponse,
    IApiDateRange,
    ICategory,
    ICreateCategory,
    ICreatePaymentRecord,
    IDateRange,
    IPaymentRecord,
    IStatementSummary,
    IToken,
    IUpdatePaymentRecord,
    SupportedBank,
    TransactionType,
} from './DataTypes';

const BASE_URL =
    process.env.NODE_ENV == 'production'
        ? process.env.REACT_APP_API_URI_ENV
        : 'http://127.0.0.1:9001/api/';

const analyserApiClient = ky.create({
    prefixUrl: BASE_URL,
    hooks: {
        beforeRequest: [
            request => {
                const token = getSessionParam(AUTH_TOKEN_PARAM);
                if (token) {
                    request.headers.set('Authorization', `Bearer ${token}`);
                }
            },
        ],
        afterResponse: [
            async (request, options, response) => {
                if (response.status === 403) {
                    const newToken = handleRefreshToken();
                    if (newToken) {
                        handleRefreshTokenSuccess(newToken);
                        request.headers.set(
                            'Authorization',
                            `Bearer ${newToken.accessToken}`
                        );
                        return ky(request);
                    }
                }
            },
        ],
    },
    mode: 'cors',
    timeout: false,
});

const handleRefreshToken = (): IToken | undefined => {
    const refreshToken = getSessionParam(REFRESH_TOKEN_PARAM);
    const userEmail = getSessionParam(USER_EMAIL_PARAM);
    let responseToken: IToken | undefined;

    if (refreshToken) {
        analyserApiClient
            .post('auth/refresh-token', {
                json: {
                    refreshToken: refreshToken,
                    email: userEmail,
                },
            })
            .json<IToken>()
            .then((response: IToken) => {
                if (response != null && response.accessToken) {
                    responseToken = response;
                } else {
                    useHandleLogout();
                }
            })
            .catch(error => {
                useHandleLogout();
            });
    }
    return responseToken;
};

export const handleLogin = async (
    email: string,
    password: string
): Promise<Response> => {
    return await analyserApiClient.post(`auth/login`, {
        json: {
            email: email,
            password: password,
        },
    });
};

export const handleRegister = async (
    email: string,
    password: string
): Promise<Response> => {
    return await analyserApiClient.post(`auth/register`, {
        json: {
            email: email,
            password: password,
        },
    });
};

export const fetchExpenseCategoriesData = async (
    dateRange: IDateRange,
    abortSignal?: AbortSignal
): Promise<ICategory[]> => {
    return await analyserApiClient
        .get(
            `statistics/expenses/from/${dateRange.dateFrom}/till/${dateRange.dateTo}`,
            { signal: abortSignal }
        )
        .then(response => {
            return returnValueOrDefaultIfError(response, []);
        })
        .catch(error => {
            console.error(error);
        });
};

export const fetchExpenseUnassignedPayments = async (
    dateRange: IDateRange,
    abortSignal?: AbortSignal
): Promise<ICategory> => {
    return await analyserApiClient
        .get(
            `statistics/expenses/from/${dateRange.dateFrom}/till/${dateRange.dateTo}/unassigned`,
            { signal: abortSignal }
        )
        .then(response => {
            return returnValueOrDefaultIfError(response, null);
        })
        .catch(error => {
            console.error(error);
        });
};

export const fetchRevenueUnassignedPayments = async (
    dateRange: IDateRange,
    transactionType: TransactionType,
    abortSignal?: AbortSignal
): Promise<ICategory> => {
    const type =
        transactionType === TransactionType.EXPENSE ? 'expenses' : 'revenues';
    return await analyserApiClient
        .get(
            `statistics/${type}/from/${dateRange.dateFrom}/till/${dateRange.dateTo}/unassigned`,
            { signal: abortSignal }
        )
        .then(response => {
            return returnValueOrDefaultIfError(response, null);
        })
        .catch(error => {
            console.error(error);
        });
};

export const fetchStatisticsCategoriesData = async (
    dateRange: IDateRange,
    transactionType: TransactionType,
    abortSignal?: AbortSignal
): Promise<ICategory[]> => {
    const type =
        transactionType === TransactionType.EXPENSE ? 'expenses' : 'revenues';
    return await analyserApiClient
        .get(
            `statistics/${type}/from/${dateRange.dateFrom}/till/${dateRange.dateTo}`,
            { signal: abortSignal }
        )
        .then(response => {
            return returnValueOrDefaultIfError(response, []);
        })
        .catch(error => {
            console.error(error);
        });
};

export const fetchIncomeData = (): Promise<IStatementSummary> => {
    return analyserApiClient.get(`statistics/revenue`).json();
};

export const fetchExpenseData = (): Promise<IStatementSummary> => {
    return analyserApiClient.get(`statistics/expense`).json();
};

export const fetchDateRanges = (): Promise<IApiDateRange> => {
    return analyserApiClient.get(`statistics/dateRanges`).json();
};

export const apiMoveCard = (
    paymentId: number,
    destinationCategoryId: number
): ResponsePromise => {
    const destId = destinationCategoryId ? destinationCategoryId : 'unassigned';
    return analyserApiClient.put(
        `payments/${paymentId}/move/category/${destId}`
    );
};

export const apiCreateCard = (
    categoryId: number,
    paymentRecord: ICreatePaymentRecord
): ResponsePromise => {
    return analyserApiClient.post(`payments/${categoryId}`, {
        json: paymentRecord,
    });
};

export const apiUpdateCard = (
    paymentRecord: IUpdatePaymentRecord
): ResponsePromise => {
    return analyserApiClient.put(`payments/${paymentRecord.id}`, {
        json: paymentRecord,
    });
};

export const apiDeleteCard = (
    paymenRecordId: number
): ResponsePromise => {
    return analyserApiClient.delete(`payments/${paymenRecordId}`);
};

export const apiAddSmartRule = (
    destinationCategoryId: number,
    smartRuleContent: string,
    transactionType: string
): Promise<CreateSmartRuleResponse> => {
    return analyserApiClient
        .put(
            `categories/${destinationCategoryId}/smartRule?transactionType=${transactionType}`,
            {
                json: {
                    smartRule: smartRuleContent,
                },
            }
        )
        .json();
};

export const apiEditSmartRules = (
    destinationCategoryId: number,
    newSmartRules: string[],
    transactionType: string
): Promise<CreateSmartRuleResponse> => {
    return analyserApiClient
        .put(
            `categories/${destinationCategoryId}/smartRules?transactionType=${transactionType}`,
            {
                json: {
                    smartRules: newSmartRules,
                },
            }
        )
        .json();
};

export const getAllCategoryIds = () => {
    return analyserApiClient.get(`categories`).then(response => {
        if (response.status != 200)
            console.error('Could not find categories: ' + response.statusText);
    });
};

export const apiDeleteCategoryById = (id: number): ResponsePromise => {
    return analyserApiClient.delete(`categories/${id}`);
};

export const apiUpdateCategoryById = (id: number, category: ICategory) => {
    return analyserApiClient
        .put(`categories/${id}`, {
            json: category,
        })
        .then(response => {
            if (response.status != 200) {
                console.error(
                    'Unsuccessful category update: ' + response.statusText
                );
            }
        });
};

export const apiUpdateCategoriesOrder = (
    order: any,
    transactionType: string
) => {
    return analyserApiClient
        .put(`categories/order?transactionType=${transactionType}`, {
            json: {
                order: order,
            },
        })
        .then(response => {
            if (response.status != 200) {
                console.error(
                    'Unsuccessful category update: ' + response.statusText
                );
            }
        });
};

export const fetchCategoryById = (id: number): Promise<ICategory> => {
    return analyserApiClient.get(`categories/${id}`).then(response => {
        return response.json();
    });
};

export const apiAddCategory = (
    newCategoryDto: ICreateCategory
): Promise<ICategory> => {
    return analyserApiClient
        .post(`categories`, {
            json: newCategoryDto,
        })
        .then(response => {
            return response.json();
        });
};

export const fetchSupportedBanks = (): Promise<SupportedBank[]> => {
    return analyserApiClient.get(`csv/supported-banks`).then(response => {
        return response.json();
    });
};

export const apiUploadBankStatementCsvMultipart = (
    formData: FormData
): Promise<CsvUploadResponse> => {
    return analyserApiClient
        .post(`csv/upload`, {
            body: formData,
        })
        .json();
};

const returnValueOrDefaultIfError = (response: Response, defaultValue: any) => {
    if (response.status != 200) {
        console.error('Could not find categories: ' + response.statusText);
        return defaultValue;
    } else {
        return response.json();
    }
};
