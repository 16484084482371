import React from 'react';
import { Link } from 'react-router-dom';
import { LOGIN_PATH, REGISTER_PATH } from '../../../utils/pagePaths';
import {
    LoginFormWrapper,
    LoginWrapper,
    RedirectSection,
} from '../../elements/_LoginComponents/LoginWrapper';
import styled from '@emotion/styled';

const StyledGif = styled.img`
    border-radius: 20px;
`

export const ResetPasswordPage = () => {
    const redirectedFromPath = new URLSearchParams(window.location.search).get(
        'path'
    );

    return (
        <LoginWrapper>
            <LoginFormWrapper>
                <h1>Forgot your password?</h1>
                <p>
                    No worries - it happens. But for now You have to really use
                    Your brain and recollect it.
                </p>
                <StyledGif src="https://bearfoot-graphics.co.uk/wp-content/uploads/2018/03/Brain_Training_Gif.gif" />
                {/* <p>No worries - it happens. We'll send an email with further instructions to reset Your password.</p> */}
                {/* <p>Just fill up the form.</p> */}
                {/* <input type="email" placeholder='Email' /> */}
                {/* <Button type="button">Reset</Button> */}
                <RedirectSection>
                    <span>
                        <Link
                            className="styled-link"
                            to={`${LOGIN_PATH}?path=${redirectedFromPath}`}
                        >
                            Sign in
                        </Link>
                        /
                        <Link
                            className="styled-link"
                            to={`${REGISTER_PATH}?path=${redirectedFromPath}`}
                        >
                            Create Account
                        </Link>
                    </span>
                </RedirectSection>
            </LoginFormWrapper>
        </LoginWrapper>
    );
};
