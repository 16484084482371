import styled from '@emotion/styled';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import MobileStepper from '@mui/material/MobileStepper';
import React, { FC, useEffect, useState } from 'react';
import { reloadDateRanges } from '../../../store/thunks';
import {
    IDateRange,
    IDateRangeProps,
    IDateRangeState,
} from '../../../utils/DataTypes';
import { fontColor } from '../../../utils/constants';
import {
    getActiveDatesIndex,
    prepareDateRangeRequestForMonthly,
    prepareDateRangeRequestForYearly,
} from '../utils';
import { SliderButton } from './SliderButton';
import { SwipeableDateSlider } from './SwipeableDateSlider';
import { isValidObject } from '../../pages/categoriesList/table/dndTableUtils';

const Wrapper = styled.div`
    margin: 50px auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SliderWrapper = styled.div`
    @import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
    font-family: 'Lobster', cursive;
    text-shadow: 1px 2px #7ca9af;
    color: ${fontColor};
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 3rem;
`;

const StyledMobileStepper = styled(MobileStepper)`
    margin: 0 auto;
    overflow-x: auto;
    max-width: 250px;
    .MuiMobileStepper-dotActive {
        background-color: ${fontColor};
    }

    ::-webkit-scrollbar {
        width: 0;
    }
`;

interface IProps {
    strategy: 'Yearly' | 'Monthly' | 'Custom';
    dateRange: IDateRangeState;
    setDateRange: (newRange: IDateRangeState) => void;
}

export const DateSlider: FC<IProps> = ({
    strategy,
    dateRange,
    setDateRange: setDateRange,
}) => {
    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const [maxSteps, setMaxSteps] = useState<number>(0);
    const [activeDates, setActiveDates] = useState<string[]>([]);

    function scrollDotIndicatorToActiveElement() {
        const container = document.querySelector(
            '.MuiMobileStepper-root'
        ) as HTMLElement;
        const activeElement = document.querySelector(
            '.MuiMobileStepper-dotActive'
        ) as HTMLElement;

        if (container && activeElement) {
            const containerRect = container.getBoundingClientRect();
            const activeElementRect = activeElement.getBoundingClientRect();

            // Check if active element is off to the right
            if (activeElementRect.right > containerRect.right) {
                container.scrollLeft +=
                    activeElementRect.right - containerRect.right;
            }
            // Check if active element is off to the left
            else if (activeElementRect.left < containerRect.left) {
                container.scrollLeft -=
                    containerRect.left - activeElementRect.left;
            }
        }
    }

    useEffect(() => {
        if (
            !Array.isArray(dateRange.yearly.values) ||
            dateRange.yearly.values.length < 1
        )
            reloadDateRanges().then(response => {
                if (response) {
                    setDateRange({
                        ...dateRange,
                        monthly: {
                            values: response.monthly,
                            display: response.monthly[activeStepIndex],
                        },
                        yearly: {
                            values: response.yearly,
                            display: response.yearly[activeStepIndex],
                        },
                    });
                }
            });
    }, []);

    useEffect(() => {
        var newActiveDate: IDateRange | null = null;

        if (
            strategy === 'Monthly' &&
            Array.isArray(dateRange.monthly.values) &&
            dateRange.monthly.values.length > 0
        ) {
            handleActiveDates(dateRange.monthly);
            if (isValidObject(dateRange.monthly.display)) {
                newActiveDate = prepareDateRangeRequestForMonthly(
                    dateRange.monthly.display!
                );
            } else {
                newActiveDate = prepareDateRangeRequestForMonthly(
                    dateRange.monthly.values[0]
                );
            }
        }

        if (
            strategy === 'Yearly' &&
            Array.isArray(dateRange.yearly.values) &&
            dateRange.yearly.values.length > 0
        ) {
            handleActiveDates(dateRange.yearly);
            if (isValidObject(dateRange.yearly.display)) {
                newActiveDate = prepareDateRangeRequestForYearly(
                    dateRange.yearly.display!
                );
            } else {
                newActiveDate = prepareDateRangeRequestForMonthly(
                    dateRange.yearly.values[0]
                );
            }
        }

        if (isValidObject(newActiveDate)) {
            setDateRange({
                ...dateRange,
                active: newActiveDate!,
            });
        }
    }, [strategy, dateRange.yearly, dateRange.monthly]);

    const handleActiveDates = (props: IDateRangeProps) => {
        setActiveDates(props.values);
        setActiveStepIndex(getActiveDatesIndex(props));
        setMaxSteps(props.values.length);
        scrollDotIndicatorToActiveElement();
    };

    const handleNext = () => {
        handleStepChange(activeStepIndex + 1);
    };

    const handleBack = () => {
        handleStepChange(activeStepIndex - 1);
    };

    const handleStepChange = (step: number) => {
        setActiveStepIndex(step);
        const activeStep = activeDates[step];

        if (strategy === 'Monthly') {
            setDateRange({
                ...dateRange,
                active: prepareDateRangeRequestForMonthly(activeStep),
                monthly: {
                    ...dateRange.monthly,
                    display: activeStep,
                },
            });
        }

        if (strategy === 'Yearly') {
            setDateRange({
                ...dateRange,
                active: prepareDateRangeRequestForYearly(activeStep),
                yearly: {
                    ...dateRange.yearly,
                    display: activeStep,
                },
            });
        }
    };

    return (
        <>
            {activeDates.length > 0 && (
                <Wrapper>
                    <SliderButton
                        onClick={handleBack}
                        disabled={activeStepIndex === 0}
                    >
                        <KeyboardArrowLeft />
                    </SliderButton>

                    <SliderWrapper>
                        <SwipeableDateSlider
                            activeDates={activeDates}
                            activeStep={activeStepIndex}
                            handleStepChange={handleStepChange}
                        />

                        <StyledMobileStepper
                            steps={maxSteps}
                            position="static"
                            activeStep={activeStepIndex}
                            backButton={<></>}
                            nextButton={<></>}
                        />
                    </SliderWrapper>

                    <SliderButton
                        onClick={handleNext}
                        disabled={activeStepIndex === maxSteps - 1}
                    >
                        <KeyboardArrowRight />
                    </SliderButton>
                </Wrapper>
            )}
        </>
    );
};
