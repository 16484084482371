import styled from '@emotion/styled';
import { DraggableProvided } from '@hello-pangea/dnd';
import React, { FC, useState } from 'react';
import { ICategory, ICategoryColumn } from '../../../../utils/DataTypes';
import { apiUpdateCategoryById } from '../../../../utils/api';
import {
    borderRadius,
    firstGradientColor,
    textColor,
} from '../../../../utils/constants';
import useGlobalSnackbar from '../../../elements/_Snackbar/useGlobalSnackbar';
import { isValidObject } from '../table/dndTableUtils';
import { CategoryDetailsDialog } from './CategoryDetailsDialog';
import { ColumnEditDial } from './ColumnEditDial';

const Header = styled.div<DraggableProps>`
    position: relative;
    display: flex;
    padding: 10px 0;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-top-left-radius: ${borderRadius}px;
    border-top-right-radius: ${borderRadius}px;
    background-color: ${firstGradientColor};
    transition: background-color 0.2s ease;
    color: ${textColor};
    box-shadow: 5px 2px 10px #00000030;
    margin-bottom: 10px;
`;

const Handle = styled.div`
    width: 90%;
`;

interface DraggableProps {
    draggingOver: boolean;
}

interface IProps {
    category: ICategoryColumn;
    isDraggingOver: boolean;
    handleDeleteCategory: (category: ICategoryColumn) => void;
    provided: DraggableProvided;
    reloadTable: () => void;
    isCategoryNameUnique: (categoryName: string, categoryId: number) => boolean;
    isSmartRuleUnique: (smartRule: string, categoryId?: number) => boolean;
    handleUpdateSmartRules: (newRules: string[], categoryId?: number) => void;
}

export const ColumnHeader: FC<IProps> = ({
    category,
    isDraggingOver,
    handleDeleteCategory,
    provided,
    reloadTable,
    isCategoryNameUnique,
    isSmartRuleUnique,
    handleUpdateSmartRules
}) => {
    const [rulesDialogOpen, setRulesDialogOpen] = useState<boolean>(false);
    const { Snackbar, onSnackbarOpen } = useGlobalSnackbar();

    const handleOpenRulesDialog = () => {
        setRulesDialogOpen(true);
    };

    const handleCloseRulesDialog = () => {
        setRulesDialogOpen(false);
        reloadTable();
    };

    const handleUpdateCategory = (newCategory: ICategory) => {
        apiUpdateCategoryById(category.id, newCategory)
            .then(() => {
                reloadTable();
            })
            .catch(() =>
                onSnackbarOpen({
                    type: 'failure',
                    message: 'Category update failed',
                })
            );
    };

    return (
        <>
            <Header draggingOver={isDraggingOver}>
                <Handle {...provided.dragHandleProps}>
                    <h2>
                        {category.categoryName
                            ? category.categoryName
                            : 'Unassigned*'}
                    </h2>

                    {isValidObject(category.id) && (
                        <ColumnEditDial
                            category={category}
                            handleDeleteCategory={handleDeleteCategory}
                            handleOpenRulesDialog={handleOpenRulesDialog}
                            reloadTable={reloadTable}
                        />
                    )}
                </Handle>
            </Header>

            <CategoryDetailsDialog
                open={rulesDialogOpen}
                category={category}
                handleCloseDialog={handleCloseRulesDialog}
                handleUpdateCategory={handleUpdateCategory}
                isCategoryNameUnique={isCategoryNameUnique}
                handleUpdateSmartRules={handleUpdateSmartRules}
                isSmartRuleUnique={isSmartRuleUnique}
            />
            <Snackbar />
        </>
    );
};
