import { LoginPage } from '../components/pages/public/LoginPage';
import { RegisterPage } from '../components/pages/public/RegisterPage';
import { ResetPasswordPage } from '../components/pages/public/ResetPasswordPage';
import {
    LOGIN_PATH,
    REGISTER_PATH,
    RESET_PASSWORD_PATH,
} from '../utils/pagePaths';

export const publicRoutes = [
    {
        path: LOGIN_PATH,
        element: <LoginPage />
    },
    {
        path: REGISTER_PATH,
        element: <RegisterPage />
    },
    {
        path: RESET_PASSWORD_PATH,
        element: <ResetPasswordPage />
    }
];