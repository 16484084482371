import styled from '@emotion/styled';
import { zodResolver } from '@hookform/resolvers/zod';
import ClearIcon from '@mui/icons-material/Clear';
import { FormControl, FormHelperText, InputAdornment } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { IPaymentTile } from '../../../../utils/DataTypes';
import { apiCreateCard, apiDeleteCard, apiUpdateCard } from '../../../../utils/api';
import { BasicButton } from '../../../elements/_BasicComponents/BasicButton';
import { StyledTextField } from '../../../elements/_BasicComponents/BasicTextField';
import { BasicModal } from '../../../elements/_ModalComponents/BasicModal';
import useGlobalSnackbar from '../../../elements/_Snackbar/useGlobalSnackbar';

const StyledForm = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 1rem 0;
`;

const BodyWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const CustomTextField = styled(StyledTextField)`
	min-width: 35vw;
`;

const ErrorText: React.FC<{ children: string }> = ({ children }) => {
	return (
		<FormHelperText
			sx={{
				color: 'error.main',
				paddingBottom: '0.25rem'
			}}
		>
			{children}
		</FormHelperText>
	);
};

const formSchema = z
	.object({
		title: z.string().min(1, 'Provide payment title').max(255, 'Title too long'),
		amount: z.coerce.number({ required_error: 'Provide amount' }).safe(),
		paymentDate: z.string({ required_error: 'Provide payment date' }).max(255, 'Payment date too long'),
		bookingDate: z.string().max(20, 'Booking date too long').nullable().default(null),
		paymentReceiver: z.string().max(255, 'Receiver name too long').nullable().default(null),
		targetAccountNumber: z.coerce.number().max(999999999999).nullable().default(null),
		transactionType: z.enum(['EXPENSE', 'REVENUE'] as const)
	})
	.superRefine(({ amount, transactionType }, ctx) => {
		if (transactionType === 'EXPENSE' && amount > 0) {
			ctx.addIssue({
				code: 'custom',
				message: 'EXPENSE amount must be negative',
				path: ['amount']
			});
			ctx.addIssue({
				code: 'custom',
				message: 'EXPENSE amount must be negative',
				path: ['transactionType']
			});
		}

		if (transactionType === 'REVENUE' && amount < 0) {
			ctx.addIssue({
				code: 'custom',
				message: 'REVENUE amount must be postivie',
				path: ['amount']
			});
			ctx.addIssue({
				code: 'custom',
				message: 'REVENUE amount must be postivie',
				path: ['transactionType']
			});
		}
	})
	//* This function is used to transform the data from '' and 0 to nulls
	.transform((data) => {
		return Object.fromEntries(
			Object.entries(data).map(([key, value]) => {
				if (value === '' || value === 0) {
					return [key, null];
				}
				return [key, value];
			})
		);
	});

type FormFields = z.infer<typeof formSchema>;

interface ModifyPaymentModalProps {
	open: boolean;
	handleCloseDialog: () => void;
	payment: IPaymentTile;
	categoryId?: number;
	reloadTable: () => void;
}

export const ModifyPaymentModal: React.FC<ModifyPaymentModalProps> = ({
	open,
	handleCloseDialog,
	payment,
	categoryId,
	reloadTable
}) => {
	const { Snackbar, onSnackbarOpen } = useGlobalSnackbar();
	const { control, handleSubmit, reset } = useForm<FormFields>({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		delayError: 100,
		resolver: zodResolver(formSchema),
		defaultValues: {
			title: payment.title,
			amount: payment.amount,
			paymentDate: payment.paymentDate,
			bookingDate: null,
			paymentReceiver: payment.paymentReceiver,
			targetAccountNumber: payment.targetAccountNumber,
			transactionType: payment.transactionType
		}
	});

	const handleUpdatePayment = (data: FormFields) => {
		if (payment.id === undefined || payment.id === null) {
			apiCreateCard(categoryId as number, {
				amount: data.amount as number,
				title: data.title as string,
				paymentDate: data.paymentDate as string,
				bookingDate: data.bookingDate as string,
				transactionType: data.transactionType as 'EXPENSE' | 'REVENUE',
				paymentReceiver: data.paymentReceiver as string,
				targetAccountNumber: data.targetAccountNumber as number
			})
				.then((response) => {
					if (response.status === 200) {
						onSnackbarOpen({ type: 'success', message: 'Payment created successfully' });
					}
				})
				.catch((error) => {
					onSnackbarOpen({ type: 'failure', message: 'Sth went wrong: ' + error ?? 'Unknown error' });
				})
				.finally(() => reloadTable());
		} else {
			apiUpdateCard({
				id: payment.id,
				amount: data.amount as number,
				title: data.title as string,
				paymentDate: data.paymentDate as string,
				bookingDate: data.bookingDate as string,
				transactionType: data.transactionType as 'EXPENSE' | 'REVENUE',
				paymentReceiver: data.paymentReceiver as string,
				targetAccountNumber: data.targetAccountNumber as number
			})
				.then((response) => {
					if (response.status === 200) {
						onSnackbarOpen({ type: 'success', message: 'Payment updated successfully' });
					}
				})
				.catch((error) => {
					onSnackbarOpen({ type: 'failure', message: 'Sth went wrong: ' + error ?? 'Unknown error' });
				})
				.finally(() => reloadTable());
		}
		handleClose();
	};

	const handleDeletePayment = () => {
		apiDeleteCard(payment.id)
			.then((response) => {
				if (response.status === 200) {
					onSnackbarOpen({ type: 'success', message: 'Payment deleted successfully' });
				}
			})
			.catch((error) => {
				onSnackbarOpen({ type: 'failure', message: 'Sth went wrong: ' + error ?? 'Unknown error' });
			})
			.finally(() => reloadTable());
		handleClose();
	};

	const handleClose = () => {
		reset();
		handleCloseDialog();
	};

	return (
		<BasicModal
			open={open}
			onClose={handleClose}
			heading={payment.id !== undefined ? <>Edit Payment</> : <>Add Payment</>}
			positiveButton={
				<BasicButton form="payment-form" type="submit">
					Save
				</BasicButton>
			}
			negativeButton={
				<BasicButton color={'warning'} onClick={handleClose}>
					Discard
				</BasicButton>
			}
			deleteButton={
				payment.id !== undefined && (
					<BasicButton color={'error'} onClick={() => handleDeletePayment()}>
						Delete
					</BasicButton>
				)
			}
		>
			<BodyWrapper>
				<StyledForm
					id="payment-form"
					className="payment-form"
					onSubmit={handleSubmit((data) => handleUpdatePayment(data))}
				>
					<Controller
						name="title"
						control={control}
						render={({ field: { value, onChange, onBlur, ref }, fieldState: { error } }) => (
							<FormControl>
								<CustomTextField
									label="title"
									type="text"
									placeholder="Uber eats"
									inputRef={ref}
									value={value}
									onChange={onChange}
									error={Boolean(error)}
									onBlur={onBlur}
								/>
								<ErrorText>{error?.message ?? ''}</ErrorText>
							</FormControl>
						)}
					/>
					<Controller
						name="amount"
						control={control}
						render={({ field: { value, onChange, onBlur, ref }, fieldState: { error } }) => (
							<FormControl>
								<CustomTextField
									label="amount"
									placeholder="100.00"
									type="number"
									inputRef={ref}
									value={value}
									onChange={onChange}
									error={Boolean(error)}
									onBlur={onBlur}
								/>
								<ErrorText>{error?.message ?? ''}</ErrorText>
							</FormControl>
						)}
					/>
					<Controller
						name="paymentDate"
						control={control}
						render={({ field: { value, onChange, onBlur, ref }, fieldState: { error } }) => (
							<FormControl>
								<CustomTextField
									label="paymentDate"
									placeholder="2024-03-31"
									type="date"
									inputRef={ref}
									value={value}
									onChange={onChange}
									error={Boolean(error)}
									onBlur={onBlur}
								/>
								<ErrorText>{error?.message ?? ''}</ErrorText>
							</FormControl>
						)}
					/>
					<Controller
						name="bookingDate"
						control={control}
						render={({ field: { value, onChange, onBlur, ref }, fieldState: { error } }) => (
							<FormControl>
								<CustomTextField
									label="bookingDate"
									placeholder="2024-03-31" //todo fix fucking empty value
									type="date"
									inputRef={ref}
									value={value}
									onChange={onChange}
									error={Boolean(error)}
									onBlur={onBlur}
									InputLabelProps={{
										shrink: value != ''
									}}
								/>
								<ErrorText>{error?.message ?? ''}</ErrorText>
							</FormControl>
						)}
					/>
					<Controller
						name="paymentReceiver"
						control={control}
						render={({ field: { value, onChange, onBlur, ref }, fieldState: { error } }) => (
							<FormControl>
								<CustomTextField
									label="paymentReceiver"
									placeholder="John Doe"
									type="string"
									inputRef={ref}
									value={value}
									onChange={onChange}
									error={Boolean(error)}
									onBlur={onBlur}
								/>
								<ErrorText>{error?.message ?? ''}</ErrorText>
							</FormControl>
						)}
					/>
					<Controller
						name="targetAccountNumber"
						control={control}
						render={({ field: { value, onChange, onBlur, ref }, fieldState: { error } }) => (
							<FormControl>
								<CustomTextField
									label="targetAccountNumber"
									placeholder="1234567891234567"
									type="number"
									inputRef={ref}
									value={value}
									onChange={onChange}
									error={Boolean(error)}
									onBlur={onBlur}
								/>
								<ErrorText>{error?.message ?? ''}</ErrorText>
							</FormControl>
						)}
					/>
					<Controller
						name="transactionType"
						control={control}
						render={({ field: { value, onChange, onBlur, ref }, fieldState: { error } }) => (
							<FormControl>
								<CustomTextField
									label="transactionType"
									placeholder="EXPENSE"
									type="/^(EXPENSE|REVENUE)$/"
									inputRef={ref}
									value={value}
									onChange={onChange}
									error={Boolean(error)}
									onBlur={onBlur}
									disabled={true}
								/>
								<ErrorText>{error?.message ?? ''}</ErrorText>
							</FormControl>
						)}
					/>
				</StyledForm>
				<Snackbar />
			</BodyWrapper>
		</BasicModal>
	);
};
