import styled from "@emotion/styled";
import Button from '@mui/material/Button';
import React, { FC, ReactNode } from "react";
import { elementColor, hoverColor } from "../../../utils/constants";

const StyledButton = styled(Button)`
    ${props => props.color === 'error' ? `
        color: red;
        border-color: red;
    ` : (props.color === 'warning' ? `
        color: #044240;
        border-color: #044240;
    `: `
        color: ${elementColor};
        border-color: ${elementColor};
    `)
    }
    
    
    &:hover {
        background-color: ${hoverColor};
        border-color: ${elementColor};
    }
`;

interface IProps {
    children: ReactNode,
    disabled?: boolean | undefined,
    type?: 'submit' | 'button' | 'reset'
    onClick?: () => void
    onSubmit?: () => void
    startIcon?: ReactNode
    isNegative?: boolean
    form?: string
    color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
}

export const BasicButton: FC<IProps> = ({ children, disabled, onClick, onSubmit, startIcon, color, type, form }) => {

    return (
        <StyledButton
            form={form}
            disabled={disabled}
            type={type ? type : "button"}
            color={color ? color : 'primary'}
            variant="outlined"
            size="large"
            onSubmit={onSubmit}
            startIcon={startIcon}
            onClick={onClick} autoFocus>
            {children}
        </StyledButton>
    );
}