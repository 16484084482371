import React, { FC, useState } from "react";
import { ICreateCategory } from "../../../../utils/DataTypes";
import { BasicButton } from "../../../elements/_BasicComponents/BasicButton";
import { BasicTextField } from "../../../elements/_BasicComponents/BasicTextField";
import { BasicModal } from "../../../elements/_ModalComponents/BasicModal";
import { determineLabelForCategoryNameInput, isNewNameInvalid } from "./EditCategoryNameHeading";
import { RulesTable } from "./rules/RulesTable";

interface IProps {
    open: boolean,
    transactionType: 'REVENUE' | 'EXPENSE',
    rejectAddNewCategory: () => void,
    addNewCategory: (newCategory: ICreateCategory) => void,
    isCategoryNameUnique: (name: string, categoryId?: number) => boolean,
    isSmartRuleUnique: (smartRule: string, categoryId?: number) => boolean
}

export const AddCategoryDialog: FC<IProps> = ({
    open,
    transactionType,
    rejectAddNewCategory,
    addNewCategory,
    isCategoryNameUnique,
    isSmartRuleUnique
}) => {
    const [categoryName, setCategoryName] = useState<string>('');
    const [touched, setTouched] = useState<boolean>(false);
    const [newNameTaken, setNewNameTaken] = useState<boolean>(false);
    const [keyPhrases, setKeyPhrases] = useState<string[]>([]);

    const handleCategoryNameChange = (name: string) => {
        setCategoryName(name);
        setTouched(true);
        setNewNameTaken(!isCategoryNameUnique(name));
    }

    const handleApproveNewCategory = () => {
        addNewCategory({
            categoryName: categoryName,
            keyPhrases: keyPhrases,
            transactionType: transactionType
        });
        setKeyPhrases([]);
        setCategoryName('');
    }
    const handleRejectNewCategory = () => {
        setKeyPhrases([]);
        setCategoryName('');
        rejectAddNewCategory();
        setTouched(false);
    }

    const handleKeyPhrasesUpdate = (newPhrases: string[], categoryId?: number) => {
        setKeyPhrases(newPhrases);
    }

    return (
        <BasicModal
            open={open}
            onClose={rejectAddNewCategory}
            heading={<>Add category</>}
            positiveButton={
                <BasicButton
                    onClick={handleRejectNewCategory}>
                    Close
                </BasicButton>
            }
            negativeButton={
                <BasicButton
                    disabled={isNewNameInvalid(categoryName)}
                    onClick={handleApproveNewCategory} >
                    Save
                </BasicButton>
            }
        >
            <BasicTextField
                label={determineLabelForCategoryNameInput(categoryName, newNameTaken)}
                value={categoryName}
                onChange={handleCategoryNameChange}
                error={touched ? (isNewNameInvalid(categoryName) || newNameTaken) : false}
            />
            <RulesTable
                keyPhrases={keyPhrases}
                handleUpdateSmartRules={handleKeyPhrasesUpdate}
                pagination={false}
                isSmartRuleUnique={isSmartRuleUnique}
            />
        </BasicModal>
    );
}