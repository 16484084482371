import styled from '@emotion/styled';
import { ResponsivePie } from '@nivo/pie';
import isEqual from 'lodash.isequal';
import React, { FC, useEffect, useState } from 'react';
import { ICategory, IPieChartCategory, IPieChartFill } from '../../../utils/DataTypes';
import { NoDataYet } from '../../elements/NoDataYet';
import { StyledSection } from '../../elements/_BasicComponents/Section';
import { PIE_CHART_LEGENDS } from './InsightsUtils';
import { STYLES, curencyFormatter, generateLegend, mapCategoryListToChartData } from './InsightsUtils';

export const CenteredWrapper = styled.div`
	height: 70vh;
	width: 100%;
	padding-bottom: 5%;
`;

export const CenteredHeading = styled.h2`
	text-align: center;
`;

interface IProps {
	transactionType: 'EXPENSE' | 'REVENUE';
	categories: ICategory[];
}

export const PieChart: FC<IProps> = ({ transactionType, categories }) => {
	const [noData, setNoData] = useState<boolean>(false);
	const [chartData, setChartData] = useState<IPieChartCategory[]>(mapCategoryListToChartData(categories));
	const [legend, setLegend] = useState<IPieChartFill[]>(generateLegend(categories));

	useEffect(() => {
        const newChartData = mapCategoryListToChartData(categories);
        if (isEqual(newChartData, chartData)) {
            return;
        }
		setChartData(newChartData);
		setLegend(generateLegend(categories));
	}, [categories]);

	useEffect(() => {
		setNoData(!(Array.isArray(chartData) && chartData.length > 0));
	}, [chartData]);

	return (
		<>
			{noData ? (
				<NoDataYet />
			) : (
				<>
					<StyledSection>
						<CenteredWrapper>
							<CenteredHeading>
								{transactionType === 'EXPENSE' ? 'Spending chart' : 'Income chart'}
							</CenteredHeading>
							<ResponsivePie
								data={chartData}
                                valueFormat=" < 1,"
								margin={{
									top: 30,
									right: 10,
									bottom: 100,
									left: 10,
								}}
								innerRadius={0.3}
								padAngle={1}
								cornerRadius={8}
								activeOuterRadiusOffset={15}
								borderWidth={1}
								colors={{ scheme: 'paired' }}
								borderColor={{
									from: 'color',
									modifiers: [['darker', 0.2]],
								}}
								arcLabelsSkipAngle={9}
								arcLabelsTextColor="#333333"
								arcLinkLabelsSkipAngle={8}
								arcLinkLabelsTextColor="#333333"
								arcLabel={(e) => `${curencyFormatter.format(e.value)} zl`}
								arcLinkLabel={(e) => `${e.id}`}
								fill={legend}
								defs={STYLES}
								legends={PIE_CHART_LEGENDS}
							/>
						</CenteredWrapper>
					</StyledSection>
				</>
			)}
		</>
	);
};
PieChart.whyDidYouRender = true