import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, FormControl, FormHelperText } from '@mui/material';
import { green } from '@mui/material/colors';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { z } from 'zod';
import { LOGIN_PATH } from '../../../utils/pagePaths';
import { AuthTextField } from './AuthTextField';
import { LoginFormWrapper, RedirectSection } from './LoginWrapper';

const formSchema = z
	.object({
		password: z
			.string()
			.min(8, 'Password has to contain at least 8 characters')
			.refine((value) => new RegExp('[!@#$%^&*\\_+\\:;<>,.?~\\/-]+').test(value), {
				message: 'Must contain at last one special character'
			})
			.refine((value) => new RegExp('[0-9]+').test(value), {
				message: 'Must contain at last one number'
			}),
		repeatPassword: z.string(),
		email: z.string().min(1, 'Please specify an email').email('Please specify a valid email')
	})
	.superRefine(({ password, repeatPassword }, ctx) => {
		if (password !== repeatPassword) {
			ctx.addIssue({
				code: 'custom',
				message: 'Passwords musth match',
				path: ['repeatPassword']
			});
		}
	});

type FormFields = z.infer<typeof formSchema>;

interface IProps {
	handleRegister: (email: string, password: string) => void;
	redirecteQueryParam: string;
}

export const RegisterForm: React.FC<IProps> = ({ handleRegister: handleRegister, redirecteQueryParam }) => {
	const { control, handleSubmit } = useForm<FormFields>({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		resolver: zodResolver(formSchema),
		defaultValues: {
			email: '',
			password: '',
			repeatPassword: ''
		}
	});

	return (
		<LoginFormWrapper
			className="login-form"
			noValidate
			onSubmit={handleSubmit((data) => handleRegister(data.email, data.password))}
		>
			<h1>Sign Up</h1>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: '1rem',
					p: '1rem',
					width: '30rem',
					accentColor: green
				}}
			>
				<Controller
					name="email"
					control={control}
					render={({ field: { value, onChange, onBlur, ref }, fieldState: { error } }) => (
						<FormControl>
							<AuthTextField
								name="email"
								label="Email"
								placeholder="example@mail.com"
								required
								inputRef={ref}
								value={value}
								onChange={onChange}
								onBlur={onBlur}
								error={Boolean(error)}
							/>
							<FormHelperText
								sx={{
									color: 'error.main',
									paddingBottom: '0.25rem'
								}}
							>
								{error?.message ?? ''}
							</FormHelperText>
						</FormControl>
					)}
				/>
				<Controller
					name="password"
					control={control}
					render={({ field: { value, onChange, onBlur, ref }, fieldState: { error } }) => (
						<FormControl>
							<AuthTextField
								name="password"
								label="Password"
								type="password"
								required
								inputRef={ref}
								value={value}
								onChange={onChange}
								onBlur={onBlur}
								error={Boolean(error)}
							/>
							<FormHelperText
								sx={{
									color: 'error.main',
									paddingBottom: '0.25rem'
								}}
							>
								{error?.message ?? ''}
							</FormHelperText>
						</FormControl>
					)}
				/>
				<Controller
					name="repeatPassword"
					control={control}
					render={({ field: { value, onChange, onBlur, ref }, fieldState: { error } }) => (
						<FormControl>
							<AuthTextField
								name="repeatPassword"
								label="Repeat password"
								type="password"
								required
								inputRef={ref}
								value={value}
								onChange={onChange}
								onBlur={onBlur}
								error={Boolean(error)}
							/>
							<FormHelperText
								sx={{
									color: 'error.main',
									paddingBottom: '0.25rem'
								}}
							>
								{error?.message ?? ''}
							</FormHelperText>
						</FormControl>
					)}
				/>
				<Button type="submit">Register</Button>
			</Box>
			<RedirectSection>
				<span>
					Already have an account?
					<Link className="styled-link" to={`${LOGIN_PATH}${redirecteQueryParam}`}>
						Sign In
					</Link>
				</span>
			</RedirectSection>
		</LoginFormWrapper>
	);
};
