import styled from '@emotion/styled';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DialogContentText from '@mui/material/DialogContentText';
import React, { FC, useEffect, useState } from 'react';
import { BasicButton } from '../../../../elements/_BasicComponents/BasicButton';
import { BasicTextField } from '../../../../elements/_BasicComponents/BasicTextField';
import { BasicTooltip } from '../../../../elements/_BasicComponents/BasicTooltip';
import { BasicModal } from '../../../../elements/_ModalComponents/BasicModal';

const StyledHeading = styled.div`
	display: flex;
    align-items: center;
    
    & > *:last-child {
        margin-left: .2rem;
    }
`;

export const getSmartRuleLabel = (title: string, isUnique: boolean): string => {
	if (!isUnique) {
		return 'Rule already exists';
	} else if (title.trim().length > 100) {
		return 'Too long';
	} else if (title.trim().length < 1) {
		return 'Too short';
	} else {
		return 'Smart rule';
	}
};

interface IProps {
	handleApiAddRule: (targetCategoryId: number, ruleContent: string) => void;
	paymentTitle: string;
	targetCategoryId: number;
	open: boolean;
	handleClose: () => void;
	isSmartRuleUnique: (smartRule: string, categoryId?: number) => boolean;
}

export const SmartRuleDialog: FC<IProps> = ({
	open,
	handleClose,
	paymentTitle,
	targetCategoryId,
	handleApiAddRule,
	isSmartRuleUnique
}) => {
	const [ruleContent, setRuleContent] = useState<string>('');
	const [label, setLabel] = useState<string>('Smart rule');
	const [isError, setIsError] = useState<boolean>(false);

	const handleInputChange = (newValue: string) => {
		setRuleContent(newValue);
		setLabel(getSmartRuleLabel(newValue, isSmartRuleUnique(newValue)));
	};

	useEffect(() => {
		setRuleContent(paymentTitle);
	}, [paymentTitle]);

	useEffect(() => {
		setIsError(label !== 'Smart rule');
	}, [label]);

	const moveManyCards = () => {
		handleApiAddRule(targetCategoryId, ruleContent);
		handleClose();
	};

	return (
		<BasicModal
			open={open}
			onClose={handleClose}
			heading={
				<StyledHeading>
					Smart rule wizzard{'   '}
					<BasicTooltip
						title="Smart rules instructions"
						content='Smart rules are used to automatically categorize payments based on their title. Smart rule may contain one or more words. Smart ruls are processed from the longest to the shortest.'
						placement="right"
						children={<HelpOutlineIcon />}
					/>
				</StyledHeading>
			}
			negativeButton={<BasicButton onClick={handleClose}>No, Just that one</BasicButton>}
			positiveButton={
				<BasicButton onClick={moveManyCards} disabled={isError}>
					Move all and create rule
				</BasicButton>
			}
		>
			<DialogContentText>
				Do You want to create smart rule and move all payment records with following title to selected category?
			</DialogContentText>
			<BasicTextField
				key="add-smart-rule"
				label={label}
				value={ruleContent}
				onChange={(event) => handleInputChange(event)}
				error={isError}
			/>
		</BasicModal>
	);
};
