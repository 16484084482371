import styled from '@emotion/styled';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PieChartIcon from '@mui/icons-material/PieChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import TableChartIcon from '@mui/icons-material/TableChart';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Avatar, Button, Chip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BsCloudUpload } from 'react-icons/bs';
import { Menu, MenuItem, ProSidebar } from 'react-pro-sidebar';
import { SidebarContent, SidebarFooter, SidebarHeader } from 'react-pro-sidebar/dist';
import 'react-pro-sidebar/dist/css/styles.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Background from '../../assets/img/background.jpg';
import { fontColor } from '../../utils/constants';
import { ANALYSE_PATH, BASE_PATH, INSIGHTS_PATH, MANAGE_DND_PATH, UPLOAD_STATEMENT_PATH } from '../../utils/pagePaths';
import { getUserEmail, useHandleLogout } from '../auth/AuthSystemUtils';
import './SideBar.scss';

const UserWrapper = styled.div`
	margin: 1rem auto;
	width: 65%;
	display: flex;
	flex-direction: column;

	.MuiChip-labelMedium {
		color: ${fontColor};
	}
`;

const StyledText = styled.div`
	text-align: center;
	padding: 24px;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 14px;
	letter-spacing: 1px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	cursor: pointer;
`;

const StyledBsCloudUpload = styled(BsCloudUpload)`
	font-size: 19px;
`;

const StyledLogoText = styled.div`
	color: ${fontColor};
`;
const Sidebar = styled(ProSidebar)`
	position: fixed;
	text-shadow: 0 0 5px #000;
	box-shadow: 0 0 10px #000;
	width: 270px;
	height: 100%;
`;
export const SideBar = (props: any): React.ReactElement => {
	const location = useLocation();
	const navigate = useNavigate();
	const username = getUserEmail();
	const [isDashboardActive, setDashboardActive] = useState<boolean>(false);
	const [isPieChartActive, setPieChartActive] = useState<boolean>(false);
	const [isLineChartActive, setLineChartActive] = useState<boolean>(false);
	const [isCategoriesBoardActive, setCategoriesBoardActive] = useState<boolean>(false);
	const [isUploadStatementActive, setUploadStatementActive] = useState<boolean>(false);

	useEffect(() => {
		setActiveTab(location.pathname);
	}, [location]);

	const setActiveTab = (path: string): void => {
		//todo refactor that shit
		switch (path) {
			case BASE_PATH: {
				setDashboardActive(true);
				setPieChartActive(false);
				setCategoriesBoardActive(false);
				setLineChartActive(false);
				setUploadStatementActive(false);
				break;
			}
			case INSIGHTS_PATH: {
				setDashboardActive(false);
				setPieChartActive(true);
				setCategoriesBoardActive(false);
				setLineChartActive(false);
				setUploadStatementActive(false);
				break;
			}
			case ANALYSE_PATH: {
				setDashboardActive(false);
				setLineChartActive(true);
				setCategoriesBoardActive(false);
				setPieChartActive(false);
				setUploadStatementActive(false);
				break;
			}
			case MANAGE_DND_PATH: {
				setDashboardActive(false);
				setCategoriesBoardActive(true);
				setLineChartActive(false);
				setPieChartActive(false);
				setUploadStatementActive(false);
				break;
			}
			case UPLOAD_STATEMENT_PATH: {
				setDashboardActive(false);
				setUploadStatementActive(true);
				setCategoriesBoardActive(false);
				setLineChartActive(false);
				setPieChartActive(false);
				break;
			}
			default: {
				break;
			}
		}
	};

	const handleLogout = () => {
		useHandleLogout();
		navigate(BASE_PATH);
	};

	return (
		<>
			<Sidebar
				image={Background}
				collapsed={props.isColapsed} // todo add dynamic screen width detection for mobile collapsed navbar
			>
				<SidebarHeader>
					<StyledText onClick={props.handleClick}>
						<TrendingUpIcon className="active" />
						<StyledLogoText>{props.isColapsed ? null : 'Analyser'}</StyledLogoText>
					</StyledText>
				</SidebarHeader>
				<SidebarContent>
					<Menu iconShape="round">
						<MenuItem icon={<DashboardIcon />} active={isDashboardActive}>
							Dashboard
							<Link to={BASE_PATH} />
						</MenuItem>
						<MenuItem
							icon={<PieChartIcon />}
							// suffix={<span className="badge red">12</span>}
							active={isPieChartActive}
						>
							Insights
							<Link to={INSIGHTS_PATH} />
						</MenuItem>
						<MenuItem icon={<ShowChartIcon />} active={isLineChartActive}>
							Analyse
							<Link to={ANALYSE_PATH} />
						</MenuItem>
						<MenuItem icon={<TableChartIcon />} active={isCategoriesBoardActive}>
							Manage
							<Link to={MANAGE_DND_PATH} />
						</MenuItem>
						<MenuItem icon={<StyledBsCloudUpload />} active={isUploadStatementActive}>
							Upload
							<Link to={UPLOAD_STATEMENT_PATH} />
						</MenuItem>
					</Menu>
				</SidebarContent>
				<SidebarFooter>
					{username ? (
						<UserWrapper>
							<Chip
								avatar={<Avatar>{username.charAt(0)}</Avatar>}
								label={username}
								onClick={() => handleLogout()}
								color="default"
								size="medium"
								variant="outlined"
							/>
						</UserWrapper>
					) : (
						<StyledText>Copyright</StyledText>
					)}
				</SidebarFooter>
			</Sidebar>
		</>
	);
};
