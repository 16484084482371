import styled from '@emotion/styled';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import React, { FC, ReactNode } from 'react';
import Background from '../../../assets/img/background.jpg';
import { fontColor } from '../../../utils/constants';
import './Login.scss';

const CenteredWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin: 0;
    box-sizing: border-box;
    overflow-y: hidden;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;
const Section = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 60%;

    @media (max-width: 768px) {
        width: 100%;
        height: 70vh;
    }
`;
const Badge = styled(Section)`
    position: relative;
    background-image: linear-gradient(rgba(10, 70, 70, 0.2), rgba(0, 0, 0, 0.6)),
        url(${Background});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    color: #eee;
    width: 40%;
    font-size: 1.2em;
    text-shadow: 2px 2px 30px #000;
    box-shadow: 0 0 10px #000;
    overflow-y: hidden;

    @media (max-width: 768px) {
        width: 100%;
        height: 30vh;
    }
`;

const LogoWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .logo {
        font-size: 4rem;
        color: ${fontColor};
    }

    .logo-text {
        font-size: 2rem;
        color: ${fontColor};
        line-height: 0;
        font-weight: bold;
    }
`;

export const Redirect = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10% 0;

    &:before {
        content: '';
        position: absolute;
        width: 70%;
        top: 0;
        border-top: 1px solid #ccc;
        text-align: center;
    }
`;

export const LoginFormWrapper = styled.form`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 3%;
`;

export const RedirectSection = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10% 0;

    &:before {
        content: '';
        position: absolute;
        width: 70%;
        top: 0;
        border-top: 1px solid #ccc;
        text-align: center;
    }
`;

interface IProps {
    children: ReactNode;
}

export const LoginWrapper: FC<IProps> = ({ children }) => {
    return (
        <CenteredWrapper>
            <Badge>
                <LogoWrapper>
                    <p>Welcome to</p>
                    <TrendingUpIcon className="logo" />
                    <p className="logo-text">ANALYSER</p>
                </LogoWrapper>
            </Badge>
            <Section>{children}</Section>
        </CenteredWrapper>
    );
};
